/**
 * Product category styling
 */
@import '../../../css/variables';

.product-category {
  font-size: 2rem;
  line-height: 2.7rem;
  font-weight: 700;
  font-family: $font-family-body;
  transition: all 250ms ease-in;
  color: $color-dark-gray;
  padding: 1rem 0 1rem 5px;
  display: block;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;

  .product-category--wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 300px;
    margin: 0 auto;
    position: relative;

    span {
      font-weight: 700;
      font-family: $font-family-body;
      margin-right: auto;
    }
  }

  .product-category--arrow {
    visibility: hidden;
    transform: scaleX(0);
    position: absolute;
    top: -7px;

    svg {
      display: block;
      margin: 0;
    }
  }

  @each $color, $hex in $colors {
    &.product-category--color-#{$color} {
      &:focus,
      &:hover,
      &:active,
      &.active {
        background: $hex;
        color: lighten($hex, 100%);
        font-weight: 900;
        outline: none;

        .product-category--wrapper {
          padding-left: 4rem;
        }

        .product-category--arrow {
          visibility: visible;
          transform: scale(0.7);
          left: 0;

          svg {
            animation-name: bounce-arrow;
            animation-timing-function: cubic-bezier(0.280, 0.840, 0.420, 1);
            animation-duration: 1.5s;
            animation-iteration-count: 1;
          }

          svg path {
            stroke: lighten($hex, 100%);
          }
        }
      }
    }
  }
}

nav .product-category {
  border-radius: 0;
}
