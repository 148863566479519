/**
 * Icon styling
 */
@import '../../../css/variables';

.icon {
  @each $color, $hex in $colors {
    &.icon--color-#{$color} {
      svg {
        color: $hex;
      }
    }
  }
}