/**
 * Button styling
 */
@import '../../../css/variables';

.button {
    padding: 1.2rem 3rem;
    font-size: 1.4rem;
    line-height: 1.2;
    text-align: center;
    font-family: $font-family-body;
    font-weight: 900;
    display: inline-block;
    color: $color-dark-gray;
    text-decoration: none;
    cursor: pointer;
    transition: all 250ms ease-in;
    text-transform: uppercase;

    &.button--type-default {
        @each $color, $hex in $colors {
            &.button--color-#{$color} {
                background: $hex;
                @if ($color == 'black') {
                    color: #fff;
                } @else {
                    color: lighten($hex, 60%);
                }

                &:focus,
                &:hover,
                &:active,
                &.active {
                    background: lighten($hex, 15%);

                    @if ($color == 'black') {
                        background: lighten($hex, 35%);
                        color: #fff;
                    } @else {
                        color: darken($hex, 35%);
                    }
                }
            }
        }
    }

    &.button--type-outline {
        background: none;
        border: 2px solid #fff;
        color: #fff;
        position: relative;

        @each $color, $hex in $colors {
            &.button--color-#{$color} {
                border-color: $hex;
                color: $hex;

                &:focus,
                &:hover,
                &:active,
                &.active {
                    background: $hex;
                    @if (lightness($hex) > 50) {
                        color: darken($hex, 100%);
                    }
                    @else {
                        color: lighten($hex, 100%);
                    }
                }
            }
        }
    }

    &.button--type-solid {
        background: $color-dark-gray;
        border-radius: 3px;
        color: white;
        @each $color, $hex in $colors {
            &.button--color-#{$color} {
                background: $hex;
                &:hover {
                    background: darken($hex, 15%);
                }
            }

        }
    }

    &.button--type-secondary {
        display: inline-flex;
        justify-content: space-between;
        align-items: center;
        padding-left: 0px;

        span {
            padding-right: 1rem;
        }
        svg {
            position: relative;
            top: -2px;
            height: 14px;
            flex: 0 0 auto;
        }

        svg path {
            transition: all 250ms ease-in;
        }

        &.reversed {
            span {
                padding-right: 0;
                padding-left: 1rem;
            }
        }

        @each $color, $hex in $colors {
            &.button--color-#{$color} {
                color: $hex;

                svg path {
                    stroke: $hex;
                }

                &:focus,
                &:hover,
                &:active,
                &.active {
                    color: darken($hex, 35%);

                    svg {
                        animation-name: bounce-arrow;
                        animation-timing-function: cubic-bezier(0.280, 0.840, 0.420, 1);
                        animation-duration: 1.5s;
                        animation-iteration-count: 1;
                    }

                    svg path {
                        stroke: darken($hex, 35%);
                    }
                }
            }
        }
    }

    &.button--type-download {
        display: inline-flex;
        align-items: center;
        padding: .5rem 3rem;
        text-align: left;

        span {
            padding-left: 1rem;
            width: 100%;
            height: 100%;
            word-break: break-word;
            overflow: hidden;
            display: flex;
            align-items: center;
        }
        svg {
            position: relative;
            top: -2px;
            height: 14px;
        }

        svg path {
            transition: all 250ms ease-in;
        }

        @each $color, $hex in $colors {
            &.button--color-#{$color} {
                background: #fff;
                color: $hex;

                svg path {
                    fill: $hex;
                }

                &:focus,
                &:hover,
                &:active,
                &.active {
                    color: darken($hex, 35%);

                    svg path {
                        fill: darken($hex, 35%);
                    }
                }

                &.button--download-full {
                    background: $hex;
                    color: #fff;

                    svg path {
                        fill: #fff;
                    }

                    &:focus,
                    &:hover,
                    &:active,
                    &.active {
                        background: darken($hex, 35%);
                    }
                }
            }
        }

        &.button--download-full.button--color-default {
            background: $color-dark-gray;
            color: #fff;
        }
    }

    &.button--no-padding {
        padding-left: 0;
        padding-right: 0;
    }

    &.button--full-width {
        width: 100%;
    }

    &.submitted {
        position: relative;
        color: transparent !important;

        &:after {
            position: absolute;
            content: '';
            width: 25px;
            height: 25px;
            border: 4px solid #fff;
            border-radius: 50%;
            border-left-color: transparent;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            animation: spin linear 1s forwards;
            animation-iteration-count: infinite;
        }
    }

    &.success {
        position: relative;
        background: #51bd80 !important;
        color: transparent !important;

        &:before {
            position: absolute;
            content: '';
            width: 25px;
            height: 12.5px;
            border: 4px solid #fff;
            border-right: 0;
            border-top: 0;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%) rotate(0deg) scale(0);
            animation: success ease-in 0.5s forwards;
        }
    }

    &.error {
        position: relative;
        animation: vibrate ease-in 0.5s forwards;
        animation-delay: 2.5s;
        background: $color-red !important;
        color: transparent !important;

        &:before{
            color: #fff;
            position: absolute;
            content: '!';
            font-size: 2.5rem;
            font-weight: bold;
            text-align: center;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%) scale(0);
            animation: error ease-in 0.5s forwards;
        }
    }
}

.white-mode {
    .button--type-solid, .button--type-default {
        background: white !important;
        color: $color-dark-gray !important;
    }
}

@keyframes bounce-arrow {
    0%   { transform: scale(1,1)      translateX(0); }
    15%  { transform: scale(1.1,.9)   translateX(0); }
    30%  { transform: scale(.9,1.1)   translateX(10px); }
    45%  { transform: scale(1.05,.95) translateX(0); }
    60%  { transform: scale(1,1)      translateX(5px); }
    75%  { transform: scale(1,1)      translateX(0); }
    100% { transform: scale(1,1)      translateX(0); }
}

@keyframes spin {
    0% { transform: translate(-50%, -50%) rotate(0deg) scale(1);}
    100% { transform: translate(-50%, -50%) rotate(360deg) scale(1); }
}

@keyframes success{
    from {
        transform: translate(-50%, -50%) rotate(0) scale(0);
    }
    to {
        transform: translate(-50%, -50%) rotate(-45deg) scale(1);
    }
}

@keyframes error {
    from {
        transform: translate(-50%, -50%) scale(0);
    }
    to {
        transform: translate(-50%, -50%) scale(1);
    }
}

@keyframes vibrate {
    0%, 30%, 60%, 85%, 100% {
        left: 0;
    }
    10%, 40%, 90%, 70% {
        left: -2px;
    }
    20%, 50%, 80%, 95% {
        left: 2px;
    }
}

@include media('<phone') {
    .button {
        &.button--no-padding {
            padding-top: 1rem;
            padding-bototm: 1rem;
        }
    }
}
