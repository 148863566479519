.image-and-text-block {
    display: flex;
    flex-direction: row;
    width: 90%;
    max-width: 1140px;
    margin: 70px auto;
    align-items: stretch;
    background-color: white;

    > div {
        width: 50%;
    }

    .video-container {
        position: relative;
        padding-bottom: 56.25%;
        height: 0;
        overflow: hidden;
        max-width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }

    .first {
        order: 1
    }

    .second {
        order: 2
    }

    .add-padding {
        margin: 0 0 30px 0;
    }

    &.white-mode{
        border-radius: 5px;
        overflow:hidden;
        box-shadow: 10px 10px 30px #b5b5b5;
        .text-content {
            background: black url('../../../img/granite-bg.jpg') no-repeat center;
            background-size: cover;
            color: #fff;

            h1,h2,h3,h4,h5,h6,p {
                color: #fff;
            }

        }
    }

    .icon {
        width: 40px;
        height: auto;
    }

    .scroll-down-arrow {
        margin-top: 3rem;
    }

    .text-content {
        background-size: cover;
        padding-top: 2rem;
        display: flex;
        flex-direction: column;
        justify-content: center;

        .content-main-wrapper {
            width: 100%;
            max-width: 570px;
        }

        &.first {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-end;
        }
        p {
            margin: 0 0 40px;
            line-height: 25px;
        }
        li > p {
          margin: 0 0 8px;
        }

        h2, h3, h4, h5, h6 {
            padding-bottom: 1.5rem;
        }

        h4{
            line-height: 35px;
        }

        .button {
            margin-top: 3.5rem;
            margin-bottom: 2.5rem;
        }

        .content-wrapper {
            width: 80%;
            max-width: 500px;
            margin: 0 auto;
        }

    }

    .image-content img {
        width: 100%;
        height: 712px;
        object-fit: cover;
        box-shadow: 10px 10px 30px #b5b5b5;
        border-radius: 5px;
    }

    .image-nocrop img{
        object-fit: contain !important;
    }

    .icon-header {
        text-transform: uppercase;
        padding-left: 75px;
        background-size: 60px;
        background-position: center left;
        background-repeat: no-repeat;
        line-height: 60px;
        position: relative;
        z-index: 1000;
        display: inline-block;
        span {
            height: 10px;
            width: 60%;
            position: absolute;
            bottom: 4px;
            left: 75px;
            z-index: -1;
            background: $color-red;
        }
    }

    &.add-in-view-on-view {
        .content-item {
            opacity: 0;
            transition-property: all;
            transition-timing-function: ease-in;
            transition-duration: 300ms;
        }
        $baseAnimationTime: 550;
        @for $i from 1 through 4 {
            .content-item:nth-child(#{$i}) {
                transition-delay: #{$baseAnimationTime*$i}ms;
            }
        }

        &.elementStaggers.in-view {
            @for $i from 1 through 4 {
                .content-item:nth-child(#{$i}) {
                    opacity: 1;
                }
            }
        }
        &.elementSlideIn{
            @for $i from 1 through 4 {
                .content-item:nth-child(#{$i}) {
                    opacity: 1;
                }
            }
            .image-content {
                transition-delay:1s;
                transition: 1s;
                opacity: 0;
                transform: translateX(-100px);
            }
        }
        &.elementSlideIn.in-view {
                .image-content {
                    opacity: 1;
                    transform: translateX(0px);
                }
        }
    }
    .smart-choice {
      h3,h4,h5 {
        font-family: $font-family-body !important;
        font-weight: 500 !important;
        text-transform: uppercase !important;
      }
    }
}

//these are style for when the block is used inside a block on the awards page.
.awards-page {
    &.image-and-text-block {
        padding-top: 2rem;
        padding-bottom: 2rem;
        .image-content img {
            height: auto;
            object-fit: cover;
            object-position: center;
            font-family: 'object-fit: cover;object-position: center;';
        }

        .text-content {
            display: flex;

            .content-wrapper {
                max-width: 39.4rem;

                h3 {
                    padding-bottom: 1rem;
                }

                p {
                    font-size: 1.5rem;
                    line-height: 3rem;
                }

                ul {
                    padding-left: 1.5rem;

                    li {
                        font-size: 1.5rem;
                        line-height: 3rem;
                        font-family: "Museo Sans", serif;
                    }
                }
            }
        }
    }
}

@include media('<=tablet') {
    .image-and-text-block .text-content.second {
        padding-left: 5rem;
        padding-right: 2rem;
    }

    .image-and-text-block .text-content.first {
        padding-right: 5rem;
        padding-left: 2rem;
    }

    .image-content img {
        min-height: 50rem;
        height: auto;
    }
    .image-and-text-block.white-mode {
        .text-content {
             .content-main-wrapper {
                width: 90%;
                margin: 0 5%;
            }
        }
    }

}

@include media('<phone') {
    .image-and-text-block {
        padding: 0;
        flex-direction: column;
        > div {
            width: 100%;
        }
        .text-content {
            padding: 4rem 2rem;
            &.first {
              padding: 6rem 0rem 2rem !important;
            }
            &.second {
              padding: 6rem 0rem 2rem !important;
            }
            .content-wrapper{
                width: 100%;
            }
        }
        .image-content {
            img {
                height: 32rem;
                min-height: unset;
                width: 100%;
                object-fit: cover;
                object-position: center;
                font-family: 'object-fit: cover;object-position: center;';
            }
        }

        h2.has-icon {
          font-size: 5.4rem;
          margin-left: 30px;
        }
    }

    .awards-page {
        &.image-and-text-block {
            .image-content {
                img {
                    height: 32rem;
                }
            }
            .text-content {
                padding: 2.5rem 2rem;
                .content-wrapper {
                    padding-bottom: 0;
                    max-width: none;
                }
            }
        }
    }
}
