.contact-details {
    background-color: #E8E8E8;

    &__container {
        max-width: 80rem;
        width: 100%;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        padding-top: 7rem;
        padding-bottom: 6rem;

    }

    &__item {
        display: flex;
        flex-direction: column;

        h2 {
            text-transform: uppercase;
        }

        h5 {
            padding-top: 4.5rem;
            padding-bottom: 1rem;
            text-transform: uppercase;
        }
    }

    .phone {
        padding-top: 2rem;
        font-family: 'Museo Sans', sans-serif;
        font-size: 3.6rem;
        line-height: 3rem;
        color: dark;
        font-weight: 300;
    }

    .hours {
        font-family: 'Museo Sans', sans-serif;
        font-size: 3rem;
        line-height: 3rem;
        color: dark;
        font-weight: 300;
    }
}

@include media ('<=tablet') {
    .contact-details__container {
        padding: 7rem 2rem 6rem;
    }
}

@include media ('<phone') {
    .contact-details {
        &__container {
            max-width: none;
            justify-content: center;
        }

        &__item {
            padding: 0 2rem;
        }
    }
}

@include media ('<smallPhone') {
    .contact-details {
        &__container {
            flex-direction: column;
            padding: 8rem 0;
        }

        &__item {
            padding: 0 2rem 9rem;

            &:nth-last-child(1) {
                padding-bottom: 0;
            }
        }
    }
}
