/**
 * Header styling
 */
@import '../../../css/variables';

header {
  transition: 0.5s;
  background: #fff;

  .logo {
    display: block;

    svg path {
      fill: $color-dark-gray;
    }

    &.mobile {
      display: none;
      z-index: 5;
    }
  }

  &.sticky {
    position: fixed !important;
    top: 0 !important;
    left: 0 !important;
    right: 0 !important;
    z-index: 9999;
  }


  //Mobile menu icon
  .menuBtn {
    transform: translate(0%, 0%);
    z-index: 21;
    width: 3.5rem;
    cursor: pointer;
    display: none;

    &.active span {
      background: #fff;
    }

    &.active span:nth-child(1) {
      animation: ease .3s top forwards;
    }

    &.not-active span:nth-child(1) {
      animation: ease .3s top-2 forwards;
    }

    &.active span:nth-child(2) {
      animation: ease .3s scaled forwards;
    }

    &.not-active span:nth-child(2) {
      animation: ease .3s scaled-2 forwards;
    }

    &.active span:nth-child(3) {
      animation: ease .3s bottom forwards;
    }

    &.not-active span:nth-child(3) {
      animation: ease .3s bottom-2 forwards;
    }

    @keyframes top {
      0% {
        top: 0;
        transform: rotate(0);
      }
      50% {
        top: 1.1rem;
        transform: rotate(0);
      }
      100% {
        top: 1.1rem;
        transform: rotate(45deg);
      }
    }

    @keyframes top-2 {
      0% {
        top: 1.1rem;
        transform: rotate(45deg);
      }
      50% {
        top: 1.1rem;
        transform: rotate(0deg);
      }
      100% {
        top: 0;
        transform: rotate(0deg);
      }
    }

    @keyframes bottom {
      0% {
        bottom: 0;
        transform: rotate(0);
      }
      50% {
        bottom: 1.1rem;
        transform: rotate(0);
      }
      100% {
        bottom: 1.1rem;
        transform: rotate(135deg);
      }
    }

    @keyframes bottom-2 {
      0% {
        bottom: 1.1rem;
        transform: rotate(135deg);
      }
      50% {
        bottom: 1.1rem;
        transform: rotate(0);
      }
      100% {
        bottom: 0;
        transform: rotate(0);
      }
    }

    @keyframes scaled {
      50% {
        transform: scale(0);
      }
      100% {
        transform: scale(0);
      }
    }

    @keyframes scaled-2 {
      0% {
        transform: scale(0);
      }
      50% {
        transform: scale(0);
      }
      100% {
        transform: scale(1);
      }
    }

    span {
      display: block;
      width: 100%;
      border-radius: 0.3rem;
      height: 0.3rem;
      background: #fff;
      transition: all .3s;
      position: relative;

      + span {
        margin-top: 0.8rem;
      }
    }
  }

  //End Mobile menu icon

  .header--container {
    display: flex;
    align-content: center;
    justify-content: space-between;
    max-width: $base-width;
    margin: 0 auto;
    padding: 1rem 0;

    .regular-sub-menu {
      ul {
        padding: 1rem 0;
        margin: 0;

        li {
          margin: 0;
          transition: 0.2s;
        }
      }
    }
  }

  .language-toggler {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .language-toggle {
    background: $color-dark-gray;
    color: #fff;
    padding: 1rem 1rem;
    line-height: 1;
    text-decoration: none;
    font-family: $font-family-menu;
    font-size: 14px;
    font-weight: bold;
  }

  .mobile-language {
    display: none;
  }

  .nav-menu-search {
    display: none;
  }

  .search-toggle {
    margin-left: 1rem;
    cursor: pointer;

    svg path,
    svg circle {
      stroke: $color-dark-gray;
    }
  }

  nav {
    display: flex;
    align-items: center;

    .product-category {
      font-size: 1.4rem;
      line-height: 2rem;
    }

    ul {
      list-style: none;
      padding: 0;
      margin: 0;
      display: flex;
      font-style: normal;
      line-height: 0;

      li {
        padding: 0;
        margin: 0;

        .product-category--wrapper {
          max-width: 100% !important;
          padding: 0 1rem 0 3rem;
          margin-left: 1rem !important;
        }

        ul {
          margin: 0;

          li {
            a {
              line-height: 1.8rem;
              padding: 1rem 1rem 1rem 4rem;
              display: block;
              font-weight: 700;
              font-family: "museo-sans", sans-serif;
              &:hover,
              &:focus {
                background: $color-red;
                color: #fff !important;
              }
            }
          }
        }

        a {
          font-family: $font-family-menu;
          font-weight: bold;
          font-size: 1.4rem;
          line-height: 1.8rem;
          color: $color-dark-gray;
          text-transform: uppercase;
          text-decoration: none;
          padding: 0 3rem 0 0;
        }

        &.has-children {
          position: relative;

          svg.menu-mobile-animation {
            display: none;
          }

          &:focus,
          &:hover {
            ul {
              display: block;
              z-index: 10;
              background: #fff;

              li a {
                color: $color-dark-gray;
              }
            }
          }

          a svg {
            margin-left: .5rem;
            display: inline;

            path {
              stroke: $color-dark-gray;
            }
          }
        }
      }

      ul {
        display: none;
        position: absolute;
        width: 400px;
        padding: 1rem 0;
        left: -3rem;
      }
    }
  }

  &.floating {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    z-index: 2;
    background: none;
  }

  &.homepage:not(.sticky) {
    .logo svg path {
      fill: #fff;
    }

    nav ul li a {
      color: #fff;

      svg path {
        stroke: #fff;
      }
    }

    .search-toggle svg path,
    .search-toggle svg circle {
      stroke: #fff;
    }

    .language-toggle {
      background: #fff;
      color: $color-dark-gray;
    }
  }

  .searcn-nav-container {
    display: none;
  }
}

header:not(.homepage){
  nav > ul > li.active a {
    color: #d2222a;
    > svg path {
      stroke: #d2222a !important;
    }
  }
}



@include media('<desktop') {
  header {
    .header--container {
      padding: 1rem 2rem;
    }
  }
}

@include media('<tablet') {
  header {
    display: flex;
    position: relative;

    .menuBtn span {
      background: black;
    }

    .header--container {
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      visibility: hidden;
      opacity: 0;
      height: 100vh;
      transform: translateX(100vw);
      transition: all 0.3s ease-in-out;
      background-color: #262626;
      display: flex;
      flex-direction: column;
      padding: 1rem 0;
      width: 100%;

      &.active {
        visibility: visible;
        transform: translateX(0);
        opacity: 1;
        z-index: 20;
        overflow-y: auto;
      }

      .logo {
        display: none;

        &.mobile {
          display: block;
        }
      }

      nav ul li.has-children:hover {
        ul {
          display: none;
          background: $color-dark-gray;
          position: initial;

          li {
            a {
              color: #fff;
            }
          }
        }
      }

      nav {
        display: flex;
        flex-direction: column;
        margin-top: 8rem;
        height: 100%;
        align-items: flex-start;
        position: relative;

        .search-toggle {
          position: absolute;
          top: 0px;
          left: 0;
          z-index: 20;
        }

        .searcn-nav-container {
          display: block;
          margin: 0 2rem 1rem;
          padding-bottom: 1rem;
          width: calc(100vw - 4rem);
          border-bottom: 2px solid #fff;

          .button {
            font-size: 1.6rem;

            svg {
              width: 22px;
              height: 22px;
              top: auto;
              position: static;
            }
          }
        }

        .regular-sub-menu, .has-children > a {
          padding-left: 2rem;
        }

        .regular-sub-menu > a {
          padding-left: 0rem;
        }

        .second-level-items > li > a {
          width: 100%;
        }

        .product-category--wrapper {
          margin: 0 0 0 30px !important;
        }

        .product-category {
          font-size: 1.4rem;
          line-height: 1.8rem;
        }

        ul {
          display: flex;
          flex-direction: column;
          padding-top: 0px;
          width: 100%;

          li {
            padding: 15px 0;

            svg.menu-mobile-animation {
              display: block;
              transform: rotate(0deg);
              transition: all 0.3s ease-in;
              margin-left: 1rem;
              margin-top: -3px;

              &.open {
                transform: rotate(180deg);
              }

            }

            svg.menu-mobile-no-animation {
              display: none;
            }

            svg.menu-mobile-animation path {
              stroke: #fff;
            }

            a {
              display: flex;
              align-items: center;
              font-size: 1.6rem;
              color: #fff;
            }

            ul li {
              a {
                font-size: 1.3rem;
                padding-left: 2rem !important;

                &.product-category {
                  padding-left: 0 !important;
                }
              }
            }
          }
        }
      }
    }

    .header--container.active ul li ul {
      display: none;
      background: $color-dark-gray;
      position: initial;
      padding: 20px 0 0;

      &.open {
        display: block;
      }

      li {
        display: flex;
        justify-content: flex-start;
        padding: 0;

        a {
          color: #fff;
          background: none;

          &.product-category .product-category--wrapper {
            padding: 0 2rem 0 4rem;
            position: relative;
          }

          &.product-category .product-category--arrow {
            visibility: visible;
            transform: scale(0.5);
            left: 10px;
            position: absolute;

            svg path {
              stroke: #fff;
            }
          }
        }

        &.has-children {
          &:focus,
          &:hover {
            ul {
              background: $color-dark-gray;
              position: initial;
              display: none;

              li a {
                color: #fff;
                background: none !important;

                .product-category--wrapper {
                  padding-left: 0;
                }

                &.product-category:focus,
                &.product-category:hover,
                &.product-category:active {
                  background: transparent;
                  color: white;
                  font-weight: 900;
                  padding-left: 0;
                }
              }
            }
          }
        }
      }
    }

    .menuBtn {
      display: block;
    }

    .logo.mobile {
      .floating svg path {
        fill: #fff;
      }

      &.menuActive {
        z-index: 18;
      }
    }

    .search-toggle {
      display: none;
    }

    .nav-menu-search {
      border-bottom: 2px solid #fff;
      padding: 8px 0px 5px;
      width: 100%;
      position: relative;
      display: block;
      margin-bottom: 40px;
    }

    .nav-menu-search svg {
      position: absolute;
      top: 16px;
      left: 0;
    }

    input.nav-input.menu-input {
      height: 35px;
      width: calc(100% - 60px);
      padding: 0 20px 0 40px;
      color: #fff;
      font-size: 1.6rem;
      background-color: transparent;
      z-index: 102;
      display: block;
      outline: none;
    }

    .nav-submit {
      position: relative;
    }

    .nav-submit-input {
      visibility: hidden;
      position: absolute;
    }

    .language-toggler {
      justify-content: flex-start;
      padding: 0 0 0 2rem;
    }

    .mobile-language {
      display: block;
      color: #fff;
      padding-top: 3rem;
      padding-bottom: 10px;

      a {
        font-weight: 900;
        font-size: 1.4rem;
        text-transform: uppercase;
        line-height: 1.8rem;
        padding: 1.5rem;
        background: #fff;
        color: $color-dark-gray;
      }
    }

    .desktop-language {
      display: none;
    }


    &.floating .language-toggle {
      background-color: #fff;
      color: #000;
      padding: 10px;
    }
  }
}

.bodyActive {
  overflow: hidden;
}
