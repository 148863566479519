.title-repeating-text {
    background-color: $color-red;


    &__container {
        max-width: 114.5rem;
        padding-top: 10.5rem;
        padding-bottom: 12.5rem;
        width: 100%;
        margin: 0 auto;
    }

    &__title {
        padding-bottom: 9rem;
    }

    &__blocks {
        display: flex;
        justify-content: space-between;

        &-item {
            width: calc(20% - 3.4rem);
            padding: 0 1.7rem;

            & h4 {
                margin-bottom: 1.5rem;
                padding-right: 1.5rem;
                height: 10.5rem;
            }

            & p {
                color: #fff;
                font-size: 1.6rem;
                font-weight: 300;
            }
        }
    }

    .title-repeating-text__blocks-item, h2 {
      opacity: 0;
      transition: 0.7s;
    }
    @for $i from 1 through 6 {
        .title-repeating-text__blocks-item:nth-child(#{$i}) {
            transition-delay: #{200*$i}ms;
        }
    }
    .in-view {
      opacity: 1 !important;
    }

}

@include media ('<desktop') {
    .title-repeating-text {
        &__container {
            padding: 8rem 2rem 8rem;
        }
    }
}

@include media ('<phone') {
    .title-repeating-text {

        &__title {
            padding-bottom: 6rem;
        }

        &__blocks {
            display: block;

            &-item {
                width: 100%;
                margin-bottom: 3.5rem;
                padding: 0;

                &:nth-last-child(1) {
                    margin-bottom: 0;
                }

                h4 {
                    height: auto;
                    margin-bottom: 1.5rem;
                }
            }
        }

    }
}
