/**
 * ShowWhereToBuy styling
 */
@import '../../../css/variables';

.where-to-buy {
  text-align: center;
  .wtb-wrapper {
      padding: 0 5%;
      margin: 0 auto 0;
      max-width: 130rem;
  }
  .flags {
    display: inline-block;
    margin: 25px;
    width: 200px;
    margin-bottom: 100px;
    cursor:pointer;
    padding: 10px;
    &:hover, &.active {
      outline: 1px solid #3b86eb;
      opacity: 1;
    }
    &.hide{
      opacity: 0.3;
    }
  }
  .flex {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    .wtb-block {
      display: inline-block;
      width: 20%;
      padding: 20px 25px;
      img{
        width: 100%;
      }
    }
    &.no-wrap{

      align-items: flex-start;
    }
  }
  .wrapper {
    width: 80%;
    margin: 50px auto;
    max-width: 114rem;
  }

  .container {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 14px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    text-align: left;
  }

  .container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #eee;
  }

  .container:hover input ~ .checkmark {
    background-color: #ccc;
  }

  .container input:checked ~ .checkmark {
    background-color: #D5001B;
  }

  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  .container input:checked ~ .checkmark:after {
    display: block;
  }
  .container .checkmark:after {
    left: 9px;
    top: 4px;
    width: 7px;
    height: 14px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
  .option {
    width: 33%;
    margin: 5px 0;
  }

  p {
    font-weight: 600;
    font-size: 1.8rem;
    max-width: 114rem;
    margin: 0 auto;
  }
}


@include media('<phone') {
  .where-to-buy {
    h3{
      margin-top: 45px;
    }
    .option {
      width: 100%;
      margin: 5px 0;
    }
    .flags {
      display: inline-block;
      margin: 25px 0;
      width: 49%;
    }
    .flex {
      .wtb-block {
        display: inline-block;
        width: 50%;
        padding: 15px;
        img{
          width: 100%;
        }
      }
    }
  }

}
