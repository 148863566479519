.full-width-image {
    display: flex;
    justify-content: center;
    img {
        width: 100%;
        max-width: 50%;
        height: 500px;
        object-fit: cover;
        font-family: 'object-fit: cover;';
        flex: 1 1 auto;
    }
}

@include media ('<phone') {
    .full-width-image {
        display: block;
        img {
            height: 320px;
            max-width: none;
        }
    }
}