.awards {

    .pagination .pagination-button{
        color: white;
    }
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
    padding-top: 14.5rem;
    padding-bottom: 4.4rem;
    background-image: url('../../../img/granite-bg.jpg');

    &__container {
        position: relative;
        max-width: 1150px;
        width: 100%;
        margin: 0 auto;
        padding: 0 2rem;
    }

    &__header {
        display: flex;
        align-items: center;
        padding-bottom: 10.4rem;
        .awards-icon{
          width: 70px;
          height: 70px;
          svg {
            width: 70px;
            height: 70px;
          }
        }
        svg g path {
            color: $color-red;
        }

        svg g use {
            color: $color-red;
        }

        h2 {
            padding-left: 3rem;

            span {
                background-image: linear-gradient(#D2222A, #D2222A)!important;
            }
        }

        img {
            padding-right: 4.5rem;
        }
    }

    &__items {
        display: none;
        flex-wrap: wrap;
        margin: 0 -2.5rem;

        &.active {
            display: flex;
        }

        &--item {
            width: calc(50% - 5rem);
            margin: 0 2.5rem 10rem;
            display: flex;

            &-image {
                width: 20rem;
                flex: 0 0 auto;

                & img {
                    width: 100%;
                    height: 26.5rem;
                    object-fit: contain;
                    object-position: center;
                    font-family: 'object-fit: cover;object-position: center;';
                }
            }

            &-text {
                margin-left: 3.5rem;
                color: #000;

                ul {
                    padding-left: 1rem;
                    padding-top: 5px;
                    padding-bottom: 5px;

                    li {
                        color: #fff;
                        font-size: 1.4rem;
                        padding-bottom: 1rem;

                        a {
                            color: $color-red;
                        }
                    }
                }

                h4 {
                    padding-top: 1.5rem;
                    padding-bottom: 1.5rem;
                }

                p {
                    color: #fff;
                }



            }
        }
    }
}

#awards-pagination-wrapper {
    width: 100%;
    text-align: right;
    > span {
        display: inline-block;
        padding: 15px 20px;
        margin: 3px;
        font-size: 16px;
        font-weight: bold;
        color: white;
        cursor: pointer;
        > span {
            background: white;
            padding: 15px 20px;
            margin: 3px;
            color: black;
        }
    }
    .active {
        background: $color-red;
        color: white;
    }
}

@include media ('<phone') {
    .awards {
        padding-top: 8rem;
        padding-bottom: 8rem;

        .awards__items {
            display: block;

            &--item {
                width: calc(100% - 5rem);
                display: flex;
                flex-direction: column;
                margin: 0 2.5rem 6rem;

                &:nth-last-child(1) {
                    margin-bottom: 2rem;
                }

                &-image {
                    width: 100%;
                    padding-bottom: 2rem;
                }

                &-text {
                    margin-left: 0;
                }

            }
        }

        &__header {
            display: flex;
            align-items: center;
            padding-bottom: 4rem;

            h2 {
                padding-left: 2.5rem;
            }
        }
    }
    #awards-pagination-wrapper {
        > span {
            display: inline-block;
            padding: 10px 15px;
            font-size: 12px;
            > span {
              padding: 10px 15px;
              font-size: 12px;
            }
        }
    }
}
