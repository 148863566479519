.main-wrapper {
    display: block;
    width: 80%;
    margin: 0 auto;
    max-width: 1200px;
}

.company-block {
    display: flex;
    flex-direction: row;
    border-bottom: 2px solid #d0d0d0;
    margin: 25px;
    padding: 50px 0;
    line-height: 24px;
    &:last-child {
        border-bottom: 0;
    }
    > div {
        width: 50%;
    }
    .first {
        order:1;

    }
    .second {
        order:2;
    }
    .logo{
        font-size: 14px;
        .address {
            font-family: $font-family-body;
            background: transparent url('../../../img/home.svg') no-repeat 0 2px;
            background-size: 25px;
            padding: 2px 0 0 35px;
            margin: 40px 0 0 30px;
        }
        .other-infos {
            font-family: $font-family-body;
            line-height: 2rem;
            padding: 0px 0 0 35px;
            margin-left: 30px;
            margin-top: 2rem;

            a {
                color: $color-red;
            }
        }
        img{
            width: 80%;
            margin: 25px 20% 25px 0%;
        }
        .add-in-view-on-view {
            opacity: 0;
            transition: all .6s ease-out;
            transform: translateX(-50px);
        }
        .in-view {
            opacity: 1;
            transform: translateX(0px);
        }
    }
    .logo.second {
        .add-in-view-on-view {
            opacity: 0;
            transform: translateX(50px);
        }
        .in-view {
            opacity: 1;
            transform: translateX(0px);
        }
    }
    .details {
        font-size: 14px;
        font-weight: bold;
        a{
            color: $color-red;
        }
        p{
            font-weight: normal;
            margin: 25px 0;
            max-width: 90%;
        }
        li {
            font-weight: bold;
            max-width: 90%;
            margin: 10px 0;
        }
        span{
            font-size: 15px;
            font-weight: bold;
            max-width: 200px;
            display: block;
        }
        .button{
            margin: 0;
            padding: 0;
            color: $color-red;
            background-image: url('../../../img/arrow-right.svg');
            background-position: center right;
            background-repeat: no-repeat;
            padding-right: 25px;
            margin: 20px 0 0;
        }
        .add-in-view-on-view {
            opacity: 0;
            transition: all .5s ease-out;
            transition-delay: .4s;
        }
        .in-view {
            opacity: 1;

        }
    }
}

@include media('<phone') {
    .main-wrapper {
        width: 100%;
        padding: 4rem 2rem;
    }
    .company-block {
        display: flex;
        flex-direction: column;
        border-bottom: 2px solid #d0d0d0;
        margin: 0;
        padding: 4rem 0;

        &:nth-child(1) {
            padding-top: 0;
        }

        .second {
            order: 1;
        }

        > div {
            width: 100%;
        }
        .logo {
            .address {
                margin: 2.5rem 0 0;
            }
            .other-infos {
                padding: 0px 0 0 35px;
                margin: 5px 0 0 0;
            }
            img{
                max-width: 100%;
                margin: 20px 0;
            }
        }
    }
}
