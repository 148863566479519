/**
 * typography.css
 *
 * Typography settings
 *
 */
@import 'variables.scss';

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $font-family-header;
  margin: 0;
  line-height: 1;
  text-transform: uppercase;
}

h1 {
  font-size: 12rem;
}

h2 {
  font-size: 5.5rem;
}

h3 {
  font-size: 3.6rem;
}

h4 {
  font-size: 2.8rem;
}

h5 {
  font-size: 2.4rem;
}

h6 {
  font-size: 2rem;
}

p {
  font-family: $font-family-body;
  font-size: 1.5rem;
  font-weight: 300;
  line-height: 2.6rem;

  &.uppercase {
    text-transform: uppercase;
  }

  &.ex-small {
    font-size: 1.2rem;
    font-weight: 300;
  }
}

ul, ol {
  font-family: $font-family-body;
  font-size: 1.3rem;
  font-weight: 700;
  line-height: 1.5;

  li {
    span,
    p {
      font-family: $font-family-body;
      font-size: 1.3rem;
      font-weight: 700;
      line-height: 1.5;
    }
  }
}

@include media ('<phone') {
  h2 {
    font-size: 3.2rem;
  }

  h4 {
    font-size: 2rem;
  }

  h5 {
    font-size: 1.6rem;
  }
}
