@import '../../css/variables';

.product-breadcrumbs {
  background: $color-gray;
  padding: 2rem;
  margin-top: 60px;

  .breadcrumbs-back {
      display: none;
      a {
          font-weight: bold;
      }
  }

  .breadcrumbs-desktop {
      display: block;
  }

  .product-breadcrumbs--container {
    max-width: $base-width;
    margin: 0 auto;

    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      display: flex;
      font-size: 1.2rem;
      text-transform: uppercase;

      a:focus,
      a:hover {
        text-decoration: underline;
      }

      .active {
        font-weight: 800;
        cursor: default;
      }
    }
  }
}

.product--header {
  display: flex;
  background: #fff;
  width: 90%;
  max-width: 1140px;
  margin: 70px auto;
  align-items: center;

  .product--left {
    width: 50%;
    display: flex;
    align-items: center;

    .icon {
      width: 7.5rem;

      svg {
        width: 100%;
        height: auto;
      }
    }

    .product--left-container {
      max-width: ($base-width/2)-10;
      width: 100%;
      margin-right: 10rem;
      margin-left: auto;
      font-size: 1.5rem;
      line-height: 2;
      font-family: $font-family-body;
      font-weight: 300;

      h1 {
        font-size: 3.6rem;
        line-height: 4.1rem;
        margin: 3rem 0;
      }

      ul {
        font-family: $font-family-body;
        font-size: 1.8rem;
        font-weight: 700;
        line-height: 1.5;
        li {
          span,
          p {
            font-family: $font-family-body !important;
            font-size: 1.8rem !important;
            font-weight: 700 !important;
            line-height: 1.5 !important;
          }
        }
      }
    }
  }

  .product--right {
    width: 50%;

    img {
      max-height: 80vh;
      margin: 0 auto;
    }

    @each $color, $hex in $colors {
      &.slider--color-#{$color} .tns-nav button.tns-nav-active {
        background-color: $hex;
      }
    }

    .tns-outer {
      position: relative;
    }

    .tns-nav button {
      display: block;
      width: 2rem;
      height: 2rem;
      background: #262626;
      margin: 0 1rem;
      border-radius: 100%;
      outline: none;
    }

    .tns-nav  {
      position: absolute;
      bottom: 4rem;
      left: 0;
      right: 0;
      z-index: 1;
      display: flex;
      justify-content: center;
    }

    .slider {
      .slick-slide > div {
        line-height: 0;
      }

      .slick-dots {
        list-style:none;
        margin:0;
        padding:0;
        position: absolute;
        bottom:4rem;
        left:0;
        right:0;
        display: flex;
        justify-content: center;

        li {
          margin: 0 1rem;

          button {
            background: $color-dark-gray;
            width: 2rem;
            height: 2rem;
            border-radius: 100%;
            font-size: 0;
            color: transparent;
            outline: none;
          }
        }
      }
    }

    @each $color, $hex in $colors {
      .slider--color-#{$color} {
        .slick-dots li.slick-active button,
        .slick-dots li button:hover,
        .slick-dots li button:focus,
        .slick-dots li button:active {
          background: $hex;
        }
      }
    }
  }
}

@include media('<=tablet') {
  .product--header .product--left .product--left-container {
    margin-right: 5rem;
    padding-left: 2rem;
  }
}

@include media('<phone') {
    .product-breadcrumbs {
        display: block;
        .breadcrumbs-back {
            display: flex;
            align-items: center;
        }
        .breadcrumbs-desktop {
            display: none;
        }
        .column-center {
            display: flex;
            align-items: center;
            padding-left: 15px;
        }
    }
    .product--header {
      flex-direction: column;
      margin-top: 0;

      .product--left {
        flex-basis: 100%;
        order: 2;
        width: 100%;
        .product--left-container {
          flex-direction: column;
            display: flex;
            flex-wrap: wrap;
            padding: 50px 6%;
            margin: 0;
            > .icon {
                width: 100px;
                svg {
                    width: 75px;
                }
            }
            > .header {
                width: calc(100% - 100px);
            }
            > ul {
                width: 100%;
                padding: 0 6%;
              font-size: 1.6rem;
                li {
                    margin: .5rem 0;
                }
            }
        }
      }
      .product--right {
        flex-basis: 100%;
        order: 1;
        width: 100%;
      }
    }

    .product--specifications-container {
        width: 96%;
        padding: 25px 2%;

        .tabs--container {
            margin: 25px 0;
            > ul{
                flex-direction: column;
                li {
                    font-size: 18px;
                    padding: 8px 0;
                }
            }
            .specifications--tab-group {
                flex-direction: column;
                > div {
                    padding: 15px 0;
                }
            }
        }
    }

    .spin--container {
        flex-direction: column;
        .spin--right {
            padding: 50px;
            outline: none;
            picture, img {
              outline: none;
            }
        }
    }

    .product--box img {
      height: 150px !important;
      max-width: none !important;
      width: auto !important;
      margin: 20px auto !important;

    }

}
