/**
 * Downloads styling
 */
@import '../../../css/variables';

.downloads {
  background: $color-gray;

  &--container {
    max-width: $base-width;
    margin: 0 auto;
    display: flex;
    padding: 5rem 0;
  }

  .downloads-paginate-container, .videos-paginate-container {
    width: 100%;
    display: none;

    &.active {
      display: block;
    }
  }

  &--left {
    flex-basis: 50%;
    display: flex;
    align-items: center;
  }

  &--right {
    display: flex;
    align-items: center;
    flex-flow: wrap;
    flex-basis: 80%;

    .button {
      width: calc(32% - 0.5rem);
      margin: 0.5rem;
      height: 72px;
    }

    .button:nth-child(1),
    .button:nth-child(2) {
      margin-top: 0;
    }

    .button:nth-child(odd) {
      margin-right: .5rem;
    }
    .button:nth-child(even) {
      margin-left: .5rem;
    }
  }
}

@include media('<=tablet') {
  .downloads {
    &--left {
      padding-left: 2rem;
    }

    &--right {
      .button {
        width: calc(50% - 1rem);
      }
    }
  }
}

@include media ('<phone') {
  .downloads {
    &--container {
      padding: 5rem 2rem;
      display: block;
    }

    &--left {
      padding-bottom: 6rem;
    }
  }
}

@include media ('<smallPhone') {
  .downloads {
    &--container {
      padding: 8rem 2rem;
    }
    &--right {
      display: block;

      .button {
        width: 100%;
        margin: 0 0 2rem;

        &:nth-child(2n) {
          margin-left: 0;
        }
      }
    }
  }
}
