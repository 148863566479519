.logoGrid {
    &__container {
        max-width: 110.3rem;
        width: 100%;
        margin: 0 auto;
        padding-top: 13.1rem;
        padding-bottom: 13.1rem;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    &__header {
        align-self: flex-start;
        padding-bottom: 2.5rem;

        h2 {
            padding-bottom: 3.5rem;
        }

        p {
            font-weight: 300;
            max-width: 48.2rem;
        }
    }

    &__grid {
        max-width: 104.3rem;
        width: 100%;
        margin: 0 -3.5rem;
        padding-top: 5.5rem;
        display: flex;
        justify-content: center;

        &--container {
            display: flex;
            flex-wrap: wrap;
        }

        &--item {
            max-width: 14.7rem;
            height: 11.0rem;
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 0 1rem 4rem;

            & img {
                width: 100%;
            }
        }
    }
}


@include media ('<desktop') {
    .logoGrid {
        &__container {
            padding: 13.1rem 2rem 20rem;
            justify-content: center;
        }

        &__grid{
            &--container {
                justify-content: center;
            }
        }
    }
}

@include media ('<phone') {
    .logoGrid {
        &__container {
            padding: 8rem 2rem;
            justify-content: center;
        }

        &__grid{
            padding-top: 3.5rem;
            &--container {
                justify-content: center;
            }

            &--item {
                width: calc(50% - 2rem);
                margin: 0 1rem 2rem;
                text-align: center;
                img {
                    width: auto;
                }
                &:nth-last-child(1) {
                    margin-bottom: 0;
                }
            }
        }
    }
}
