.big-pic-with-squares {
    display: flex;
    flex-direction: row;
    width: 100%;
    background-color: white;

    > .big-pic {
        width: 66.66%;
        background-size: cover;
        background-position: center;
        position: relative;
        img {
            width: 100%;
            height: 960px;
            object-fit: cover;
            font-family: 'object-fit: cover;';
        }
    }

    > .right-content {
        width: 33.33%;
        display: flex;
        flex-direction: column;
        perspective: 1300px;
        transform-style: preserve-3d;
    }

    .square {
        height: 480px;
        display: block;
        display: flex;
        flex-direction: column;
        padding: 0 15% 0 10%;
        justify-content: center;
        align-items: flex-start;
        flex-grow: 1;
        p, h4 {
            color: white;
            margin: 5px 0;
        }
        h4 {
            line-height: 30px;
            text-transform: uppercase;
        }

    }

    .square.back1{
        background-size: cover;
        background-position: center;
        background-image: url('../../../img/granite-bg.jpg');
    }

    .square.red-back{
        background: $color-red;

        &.animated {
            transition-duration: .7s;
            transition-property: transform, opacity;
            transition-delay: .15s;
            transition-timing-function: ease-in-out;
            transform: rotatex(-80deg) translateZ(0);
            transform-origin: 0 0 0;
            opacity: 0;

            &.in-view {
                transform: rotatex(0deg) translateZ(0);
                opacity: 1;
            }
        }

        .button{
            color: white;
            background-image: url('../../../img/arrow-right-white.svg');
        }
    }

    .button{
        margin: 0;
        padding: 0;
        color: black;
        background-image: url('../../../img/arrow-right.svg');
        background-position: center right;
        background-repeat: no-repeat;
        padding-right: 25px;
        margin: 20px 0 0;
        display: inline-block;
    }

    .black-overlay {
        width: 100%;
        background: black;
        opacity: 0.6;
        height: 40%;
        position: absolute;
        bottom: 0;
        svg {
            position: absolute;
            top: 15%;
            left: 5%;
        }
        p {
            font-family: Helvetica;
            font-size: 31px;
            color: #FFFFFF;
            line-height: 47px;
            max-width: 80%;
            position: absolute;
            top: 40%;
            left: 10%;
        }
    }

    .add-in-view-on-view {
        opacity:0;
        transition: 0.8s;

    }

    .add-delay1{
        transition-delay: 0.2s;
        transform: translateX(25px);
    }
    .add-delay2{
        transition-delay: 0.4s;
        transform: translateX(25px);
    }
    .from-top{
        transition-delay: 0.2s;
        transform: translateY(-10px);
    }

    .in-view{
        opacity:1 !important;
        transform: translateX(0px) !important;
    }

}


@include media('<phone') {
    .big-pic-with-squares {
        flex-direction: column;
        > .big-pic {
            width: 100%;
            height: 350px !important;
            img {
              height: auto;
            }
        }
        > .right-content {
            width: 100%;
            .square{
              height: 200px;
            }
        }
        .black-overlay {
            height: 70%;
            p {
                font-size:20px;
            }
        }
    }
}
