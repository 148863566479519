/**
 * Tips Neo block type styling
 */

@import '../../../css/variables';

.header-with-underline {
  position: relative;
  padding-bottom: 4.5rem;
}

.tips {
  background-color: white;

  &__container {
    position: relative;
    width: 90%;
    max-width: 1140px;
    margin: 70px auto;
  }

  &__item {
    display: flex;
  }

  &__image {
    width: 50%;
    border-radius: 5px;
    box-shadow: 10px 10px 30px #b5b5b5;

    img {
      border-radius: 5px;
    }

    &--container {
      height: 100%;
    }
  }

  &__text {
    width: 50%;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    padding: 0 17rem 0 0;

    &__wrapper {
      max-width: 40rem;
    }

    & h2 {
      display: inline-block;
      position: relative;
      z-index: 1;
      line-height: 3.5rem;
    }

    .button {
      max-width: 20rem;
      margin-top: 0.5rem;
    }

    &-tips ul {
      padding-left: 0;
      margin: 0 0 0 -4px;
      font-style: normal;

      li {
        margin-bottom: 3.5rem;
        position: relative;
        list-style-type: none;

        .tips--title {
          transition: all 0.2s ease-out;
          transform: translateX(-2rem);
          font-family: $font-family-menu;
          font-weight: 700;
          font-size: 2rem;
          color: $color-dark-gray;
          line-height: 1.5;
        }

        a {
          display: flex;
          align-items: center;
          outline: none;
        }

        a:hover .tips--title,
        a:focus .tips--title,
        a:active .tips--title {
          transform: translateX(2rem);
        }

        @each $color, $hex in $colors {
          &.tips--color-#{$color} {
            a:focus,
            a:hover,
            a:active {
              font-weight: 900;

              .tips--arrow {
                visibility: visible;
                transform: scale(1);
                left: 0;

                svg {
                  animation-name: bounce-arrow;
                  animation-timing-function: cubic-bezier(0.280, 0.840, 0.420, 1);
                  animation-duration: 2s;
                  animation-iteration-count: 1;
                }

                svg g {
                  stroke: $hex;
                }
              }

              .tips--title {
                color: $hex;
              }
            }
          }
        }
      }
    }
  }

  &__image {
    width: 50%;
    position: relative;

    img {
      width: 100%;
    }
  }
}

@include media('<=tablet') {
  .tips__text {
    padding: 2rem;
  }

  .tips__text-tips ul li {
    margin-bottom: 2rem;
  }

  .tips__text-tips ul li .tips--title {
    font-size: 1.7rem;
  }
}

@include media('<phone') {
  .tips {
    &__container {
      padding: 0rem 0;

      .tips__item {
        display: flex;
        flex-direction: column;
        padding-bottom: 0;

        .tips__text {
          height: auto;
          padding: 4rem 0;
          width: calc(100% - 4rem);
          margin: 0 2rem;
          align-items: stretch;

          .header-with-underline {
            padding-bottom: 2rem;
          }

          a.button {
            margin-top: 3.5rem;
            margin-bottom: 2.5rem;
          }

          ul {
            margin: 1.5rem 0 1.5rem -0.4rem;

            li {
              margin-bottom: 3rem;

              .tips--title {
                font-size: 1.6rem;
                line-height: 2rem;
              }
            }
          }
        }

        .tips__image {
          height: auto;
          width: 100%;

          img {
            height: 32rem;
            object-fit: cover;
            font-family: 'object-fit: cover;';
          }
        }
      }
    }
  }
}
