.video-button-block {
  &--container {
    max-width: $base-width;
    margin: 0 auto;
    padding: 5rem 0;
  }

  &--header {
    margin-bottom: 2rem;

    h1,h2,h3,h4,h5,h6 {
      margin-bottom: 2rem;
    }
  }

  &--grid {
    margin-top: 2rem;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-column-gap: 1rem;
    grid-row-gap: 1rem;

    .video-button {
      display: flex;
      align-items: center;
      justify-content: center;

      figcaption {
        font-family: $font-family-body;
        font-size: 1.2rem;
        font-weight: 300;
        height: 36px;
        max-height: 36px;
        overflow: hidden;
      }
    }
  }
}

@include media('<=tablet') {
  .video-button-block {
    &--grid {
      grid-template-columns: repeat(3, 1fr);
    }
  }
}

@include media ('<phone') {
  .video-button-block {
    &--container {
      padding: 5rem 2rem;
    }

    &--grid {
      grid-template-columns: repeat(2, 1fr);

      .video-button {
        figcaption {
          height: auto;
          max-height: none;
        }
      }
    }
  }
}

@include media ('<smallPhone') {
  .video-button-block {
    &--grid {
      grid-template-columns: repeat(1, 1fr);
    }
  }
}
