/**
 * Search styling
 */
@import '../../../css/variables';

.search--page {
  .ais-Hits-list {
    list-style: none;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    margin: 3rem -1.5rem;

    .ais-Hits-item {
      margin: 0 1.5rem 2rem 1.5rem;
      flex: 0 1 auto;
      width: 100%;
      max-width: calc((100% / 3) - 3rem);
    }

    .product--box {
      height: 100%;
      display: flex;
      flex-flow: column;
      border: 2px solid $color-medium-gray;
      background: #fff;
      transition: all 250ms ease-in;
      position: relative;
      padding-bottom: 6rem;

      .button {
        position: absolute;
        bottom: 0;
        left: 2rem;
      }

      .product--category--box-header {
        font-size: 2rem;
        line-height: 2.6rem;
        font-weight: 700;
        font-family: $font-family-body;
        color: $color-dark-gray;
        margin: 1rem 0;
      }

      .product--category--box-description {
        font-size: 1.4rem;
        line-height: 2;
        color: $color-dark-gray;
      }

      .product--category--box-image {
        max-width: 200px;
        width: 100%;
        height: 200px;
        margin: 25px auto !important;
      }

      .product--box-container {
        padding: 1rem 2rem 2rem;
      }

      @each $color, $hex in $colors {
        &.product--#{$color} {
          color: $hex;

          .product--category-photo {
            border-color: $hex;
          }

          &:hover,
          &:active {
            color: $hex;
            box-shadow: 0 0 0 3px $hex;

            .product--category-photo {
              border-color: $hex;
            }
          }
        }
      }
    }
  }

  .helpfultips {
    margin: 0 0 4rem 0;

    p {
      font-size: 1.75rem;
    }

    ul li {
      font-size: 1.75rem;
      font-weight: normal;
      line-height: 1.5;
      margin-bottom: 0.5rem;
    }
  }

  .helptips-button {
    font-size: 1.75rem;
    margin-bottom: 2rem;
    font-weight: 500;
    background: url("data:image/svg+xml,%3Csvg width='13' height='9' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1.383l5.302 5.234 5.302-5.234' stroke='%23000' stroke-width='3' fill='none'/%3E%3C/svg%3E") left center no-repeat;
    padding-left: 2rem;
  }
}
#icon-cross {
    transition: 0.2s;
    &:hover{
        transform: rotate(90deg);
    }
}

@supports (display: grid) {
  .search--page {
    .ais-InstantSearch .ais-Menu ul{
      display: grid;
      grid-gap: 1rem;
      grid-auto-flow: row;
      grid-template-columns: repeat(6, 1fr);
      margin: 0;

      li {
        height: 100%;
        margin: 0;

        a {
          height: 100%;
          display: flex;
          align-items: center;
        }
      }
    }

    .ais-Hits-list {
      display: grid;
      margin: 3rem 0;
      grid-gap: 2rem;
      grid-auto-flow: row;
      grid-template-columns: repeat(3, 1fr);

      .ais-Hits-item {
        width: 100%;
        height: 100%;
        margin: 0;
        max-width: 100%;
      }
    }
  }
}

@include media('<=tablet') {
  .search--page {
    padding: 0 2rem;
  }

  .search--page .ais-InstantSearch .ais-Menu ul {
    grid-template-columns: repeat(4, 1fr);
  }
}

@include media('<phone') {
  .search--page{
    padding: 0 2rem;
    .ais-Menu ul {
      flex-direction: column;
      li a{
        margin: 0.5rem 0;
        width: 100%;
      }
    }
    h1 {
      padding-top: 25px;
    }

    .ais-InstantSearch .ais-Menu ul {
      grid-template-columns: repeat(2,1fr);
    }

    .ais-Hits-list {
      grid-template-columns: repeat(1, 1fr);
    }
  }
}
