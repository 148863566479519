details > summary {
  list-style: none;
}
details > summary::-webkit-details-marker {
  display: none;
}

.faq-block {
    display: block;
    width: 100%;
    margin: 5rem auto;
    max-width: 114rem;
    padding-bottom: 100px;

    .bolded{
        font-weight: 900;
        color: black !important;
    }

    .single {
        padding: 3rem 0;

        img {
            margin: 0 auto;
        }
    }

    .single img,
    .half img,
    .third img {
        max-height: 700px;
    }

    ul {
        padding: 1rem 1.5rem;

        li {
            font-weight: 700;
            padding-left: 1rem;
            padding-bottom: 1rem;
        }
    }

    ol {
        padding: 1rem 1.5rem;

        li {
            padding-left: 1rem;
            padding-bottom: 1rem;
        }
    }

    h2, h3, h4, h5, h6 {
        font-family: $font-family-body;
        font-weight: 500;
    }
    h3 {
        font-size: 3.2rem;
    }

    h4 {
        font-size: 3rem;
    }

    h5 {
        font-size: 1.8rem;
    }

    h6 {
        font-size: 1.6rem;
    }

    .full-width-text {
        max-width: 785px;
        margin: 0 auto;
        padding: 30px 0;

        h2, h3, h4, h5, h6 {
            padding-bottom: 20px;
        }

        p {
            padding: 10px 0;
        }
    }

    .flex-container {
        display: flex;
        flex-direction: row;
        padding: 30px 25px;
        align-items: center;

        &.text-column {
            align-items: flex-start;
        }

        &.align-top {
            align-items: flex-start;
        }
        .half {
            width: 50%;
            padding: 25px;
        }
        .third {
            width: 33.3%;
            padding: 0 25px;
        }
        .left, .right {
            width: 50%;
        }
        .right{
            padding: 0 5%;
        }
        .left > img{
            width: 100%;
        }
        p {
            display: block;
            margin: 25px 0;
        }
    }
    .relative {
        position: relative;
    }
    .faq-categories {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        text-align: center;
        align-items: center;


        .faq-category {
            width: 15%;
            flex-grow: 1;
            padding: 2.5rem 2rem;
            border: 2px solid #777777;
            font-family: $font-family-body;
            text-transform: uppercase;
            font-size: 1.4rem;
            line-height: 1.2;
            color:#777777;
            font-weight: 900;
            cursor: pointer;
            display: flex;
            flex-direction: column;
            justify-content: center;
            margin: 0 10px 0 0;
            transition: all 250ms ease-in;
            min-height: 86px;

            &:last-child {
                margin-right: 0;
            }
        }
        @each $color, $hex in $colors {
            .faq-category-#{$color}:hover, .faq-category-#{$color}:focus, .faq-category-#{$color}.active {
              background: $hex;
              border-color: $hex;
              color: white;
            }
          }
    }
    .faq-container {
        width: 100%;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        .faq-selector {
            width: 50%;
            text-align: center;
            margin: 50px 0 0;
            padding: 15px 0;
            position: relative;
            cursor: pointer;
            h3 {
                font-family: $font-family-body;
                text-transform: uppercase;
                color:#777777;
            }
            span{
                position: absolute;
                bottom: 0;
                left: 0;
                display: block;
                width: 100%;
                height: 5px;
                background-color: $color-dark-gray;
            }
        }
        .faq-selector:nth-child(2){
            span{
                background-color: #959595;
            }
        }

        .faq-tips-container {
            width: 100%;
            font-size: 15px;
            font-family: $font-family-body;
            ul {
                list-style: none;
            }
            ul li::before {
              content: "\2022";
              color: black;
              font-weight: bold;
              display: inline-block;
              width: 1em;
              margin-left: -1em;
            }


            .faq-item {
                position: relative;
                background: #F5F5F5;
                margin-bottom: 10px;
            }
            .faq-item details{
                width: 100%;
                cursor: pointer;
                transition: all 0.3s ease-in;
                &[open]{
                    .sum-title {
                        font-weight: 900;
                    }
                }
                .detail-wrapper {
                    padding: 25px 40px;
                }
                .sum-title {
                    font-size: 1.7rem;
                    padding: 0px 65px;
                }
            }
            .faq-item summary{
                width: 100%;
                padding: 20px 0px;
            }
            .faq-item svg{
                pointer-events: none;
                width: 30px;
                position: absolute;
                top: 25px;
                right: 35px;
            }


        }

    }
}


.flex-start > div{
    align-items: start;
    padding: 25px 5% !important;
}
.first {
    order: 1
}
.second {
    order: 2
}

.tip-line {
    width: 260px;
    margin: 25px calc(50% - 130px);
    display: block;
    height: 2px;
    background-color: rgb(255, 56, 125);
}

.quote-container{
    width: 60%;
    padding: 3rem 0%;
    margin: 0 auto;
    p{
        width: 100%;
        margin: 30px 0%;
        font-family: $font-family-body;
        font-weight: 300;
        font-size: 20px;
        font-style: italic;
        line-height: 26px;
        text-align: center;
    }
}



.faq-block {
    .slick-slider, .slick-list {
        width: 100% !important;
    }
    .slick-slide {
        margin: 15px;
    }
}

.faq-slider {
    position: relative;
    pointer-events: none;
}
.faq-prev, .faq-next {
    position: absolute;
    background-size: 50px;
    width: 50px;
    height: 50px;
    background-image:url('../../../img/sliderprev.svg');
    background-repeat: no-repeat;
    top: calc(42% - 25px);
    left: -30px;
    opacity: 0.9;
    z-index: 9999;
    cursor: pointer;
}
.faq-prev {
    background-image:url('../../../img/slidernext.svg');
    left: auto;
    top: calc(40% - 25px);
    right: -30px;

}

.rotate {
    transform: rotate(180deg);
}

@include media('<=tablet') {
    .faq-block {
        padding: 0 2rem;

        .flex-container {
            padding: 0;
        }
    }
}

@include media('<phone') {
    .faq-block {
            width: 100%;
            margin: 50px 0%;
            padding: 0 2%;
            padding-bottom: 100px;
        h1 {
            font-size: 2rem;
            margin: 15px 0;
        }
        .faq-categories {
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            text-align: center;
            align-items: center;

            .faq-category {
                width: 100%;
                padding: 10px 5%;
                margin: 5px 0;
                min-height: auto;
            }
        }
        .faq-selector {
            h3 {
                font-size: 2rem;
            }
          }
          .detail-wrapper {
              padding: 0 10px !important;
          }
          .faq-item details{
              .sum-title {
                  padding: 0px 65px 0 15px !important;
              }
          }
    }

    .flex-container {
        display: flex;
        flex-direction: column !important;
        align-items: center;
        margin: 0 0 25px !important;
        padding: 0 !important;
        .left, .right {
            width: 100% !important;
            margin: 25px 0 !important;
        }
        .left > img{
            width: 100%;
        }
        .right {
            padding: 0 2%;
            h4 {
                font-family: $font-family-body;
            }
        }
    }

    .quote-container {
        width: 100%;
        margin: 30px 0;
        padding: 0 2%;
    }
}
