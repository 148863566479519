/**
 * RepeatingColumns styling
 */
@import '../../../css/variables';

.repeating--columns {
  max-width: $base-width;
  margin: 8rem auto 2rem;
  font-size: 1.5rem;

  h4, h5 {
    font-family: $font-family-body;
    font-weight: 500;
    text-transform: uppercase;
  }

  & > h1,
  & > h2,
  & > h3,
  & > h4,
  & > h5,
  & > h6 {
    margin-bottom: 2rem;
  }

  & > p {
    margin: 0;
  }

  .repeating--columns-wrapper {
    display: flex;

    .repeating--columns-column {
      display: flex;
      flex-flow: column;

      & > picture > img {
        width: 100%;
      }

      & > * {
        margin-top: 0;
        margin-bottom: 2rem;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }

    &.repeating--columns-1 .repeating--columns-column {
      width: 100%;
      margin: 2rem 0;
    }

    &.repeating--columns-2 {
      margin: 0 -1rem;

      .repeating--columns-column {
        width: 50%;
        margin: 2rem 1rem;
      }
    }

    &.repeating--columns-3 {
      margin: 0 -1rem;

      .repeating--columns-column {
        width: 33.3%;
        margin: 2rem 1rem;
      }
    }
  }

  table .button {
    width: 75%;
  }

  &.repeating--columns--less-padding {
    margin: 2rem auto;
  }
}

@include media('<=tablet') {
  .repeating--columns {
    padding: 0 2rem;
  }
}

@include media('<phone') {
  .repeating--columns{
    padding: 0 2rem;
  }

  .repeating--columns .repeating--columns-wrapper {
    flex-wrap: wrap;
    margin: 0;

    .repeating--columns-column {
      picture {
        margin: 0 -2rem 2rem -2rem;
      }
    }
  }

  .repeating--columns .repeating--columns-wrapper {
    &.repeating--columns-1 {
      flex-wrap: wrap;
      margin: 0;

      .repeating--columns-column {
        width: 100%;
        margin: 2rem 0;
      }
    }
    &.repeating--columns-2 {
      flex-wrap: wrap;
      margin: 0;

      .repeating--columns-column {
        width: 100%;
        margin: 2rem 0;
      }
    }
    &.repeating--columns-3 {
      flex-wrap: wrap;
      margin: 0;

      .repeating--columns-column {
        width: 100%;
        margin: 2rem 0;
      }
    }
  }
}
