.six-squares-block {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;

    perspective: 1300px;
    transform-style: preserve-3d;

    .red-back,
    .white-back {
        h1,h2,h3,h4,h5,h6 {
            color: #fff;
        }
    }

    > .square {
        width: 33.33%;
        min-height: 480px;
        position: relative;

        picture img {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            object-fit: cover;
            object-position: center;
            font-family: 'object-fit: cover;object-position: center;';
            width: 100%;
            height: 100%;
        }

        &.animated {
            transition-duration: .7s;
            transition-property: transform, opacity;
            transition-delay: .15s;
            transition-timing-function: ease-in-out;
            transform: rotatex(-80deg) translateZ(0);
            transform-origin: 0 0 0;
            opacity: 0;

            &.in-view {
                transform: rotatex(0deg) translateZ(0);
                opacity: 1;
            }
        }
    }

    .red-back, .white-back {
        display: flex;
        flex-direction: column;
        justify-content: center;

        > div {
            padding: 0 10%;
            max-width: 450px;
        }
    }

    .red-back,.white-back {
        background: $color-red;
        p {
            color: white;
            margin: 25px 0;
        }
    }

    .add-in-view-on-view {
        opacity:0;
        transition: 0.8s;
    }

    .from-left{
        transition-delay: 0.2s;
        transform: translateX(-25px);
    }
    .from-top{
        transition-delay: 0.2s;
        transform: translateY(-10px);
    }
}

@supports (display: grid) {
    .six-squares-block {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;

        > .square {
            width: 100%;
            height: 100%;
        }
    }
}

@include media('<phone') {
    .six-squares-block {
        display: block;
        > .square {
            width: 100%;
            min-height: 300px;
        }
    }
}
