/**
 * Pagination styling
 */
@import '../../../css/variables';


.paginate-right {
    width: 100%;
    text-align: right;
    margin-right: 25px;
    margin-top: 25px;
}
.pagination {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: flex-end;
  font-size: 1.5rem;
  font-family: $font-family-body;
  font-style: normal;
  text-transform: uppercase;

  .prev-button {
      display: none;
  }

  li {
    a, button {
      padding: 1.5rem 2.5rem;
      display: block;
      transition: all 250ms ease-in;
      font-weight: 900;
      &:focus,
      &:hover,
      &:active {
        background: $color-section-red;
        color: white;
      }
    }

    &.ais-Pagination-item--disabled {
      display: none;
    }
  }

  .ais-Pagination-list {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    font-style: normal;
  }
  .ais-Pagination-item--selected {
    background: $color-red;
    color: white;
  }

  @each $color, $hex in $colors {
    &.pagination--color-#{$color} {
        .ais-Pagination-item--selected {
          background: $hex;
          color: white;
        }
      li.active a, li.active button {
        background: $hex;
        color: lighten($hex, 45%);
      }
      li a, li button {
        &:focus,
        &:hover,
        &:active {
          background: $hex;
          color: lighten($hex, 45%);
        }
      }
    }
  }
}


@include media('<phone') {
  .pagination {
    justify-content: center;
    flex-wrap: wrap;
    li {
      a {
        padding: 0.5rem 1.5rem;
        display: block;
        transition: all 250ms ease-in;
        margin: 5px 0;

        &:focus,
        &:hover,
        &:active {
          background: $color-section-red;
          color: white;
        }
      }

      &.ais-Pagination-item--disabled {
        display: none;
      }
    }
  }
}
