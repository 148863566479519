.grey-with-one-icon {
    background-color: #E8E8E8;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 50px 0;
}

.grey-with-one-icon .icon-wrapper{
    display: flex;
    flex-direction: row;
    margin: 50px 25px;
    align-items: center;

}
.grey-with-one-icon .icon-content{
    margin: 0 0 0 50px;
    max-width: 685px;
}

.grey-with-one-icon .icon-content p{
    font-family: $font-family-body;
    font-weight: 300;
    font-size: 2rem;
    margin: 5px 0;
}

.grey-with-one-icon-description {
    margin-top: 2rem;

    h2, h3, h4, h5, h6 {
        padding-bottom: 2rem;
    }

    a {
        color: $color-red;
        font-weight: 300;
    }
}

@include media('<phone') {
    .grey-with-one-icon {
        padding: 8rem 2rem;
        margin: 4rem 0 0;

        .icon-wrapper {
            flex-direction: column;
            margin: 0;
            align-items: flex-start;

            .icon {
                padding-bottom: 2.5rem;
                > svg {
                  width: 75px;
                  height: 75px;
                }
            }

            .icon-content {
                margin: 0;
            }
        }
    }
}
