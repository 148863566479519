/**
 * Recommended styling
 */
@import '../../../css/variables';

.recommended {
  background: #fff;
  padding: 5rem 0;

  .recommended--container {
    max-width: $base-width;
    margin: 0 auto;
  }

  .recommended--products {
    display: flex;
    margin: 4rem -3rem 0;

    .recommended--product {
      width: 33.3%;
      padding: 0px 3rem;
      display: flex;
      align-items: flex-start;
      flex-flow: column;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        object-position: center;
      }

      h5 {
        margin: 4rem 0 2rem 0;
        height: 8rem;
        overflow: hidden;
      }
    }
  }
}

@include media('<=tablet') {
  .recommended {
    padding: 5rem 2rem;
  }
}

@supports (display: grid) {
  .recommended {
    .recommended--products {
      display: grid;
      margin: 4rem -3rem 0;
      grid-gap: 2rem;
      grid-auto-flow: row;
      grid-template-columns: repeat(3, 1fr);
      .recommended--product {
        width: 100%;
        height: 100%;
        margin: 0;
        max-width: 100%;
        a {
          display: flex;
          flex-flow: column;
          justify-content: space-between;
          height: 100%;
        }
      }
    }
  }
}

@include media('<phone') {
    .recommended {
      width: 100%;
      padding: 5rem 2rem;
    }
  .recommended .recommended--products {
      display: flex;
      flex-direction: column;
      .recommended--product {
        padding: 2rem;
        margin: 0;
        width: 100%;
        img {
          height: auto;
          width: 100%;
        }
      }
    }

  .recommended .recommended--products .recommended--product h5 {
    margin: 2rem 0 2rem;
    height: auto;
    font-size: 2rem;
  }

  .recommended .recommended--products {
    margin: 0;
  }
}
