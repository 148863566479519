@import '../../../css/variables';

select option {
    margin: 40px !important;
    background: rgba(0, 0, 0, 0.3) !important;
    color: #fff !important;
    text-shadow: 0 1px 0 rgba(0, 0, 0, 0.4) !important;
}

.select2 {
  width: 100% !important;
}

.select2-container--default .select2-results__option--highlighted[aria-selected] {
  background-color: $color-red !important;
}
.select2-container--default .select2-selection--single {
  font-size: 16px !important;
  line-height: 18px !important;
  border: none !important;
  color: #000 !important;
  background-color: transparent !important;
  border-bottom: 2px solid #A2A2A2 !important;
  padding: 10px 5px 10px 0px !important;
  width: 100% !important;
  height: 40px !important;
  font-weight: 300 !important;
  outline: none !important;
  display: block !important;
  border-radius: 0 !important;
}
.select2-container--default .select2-selection--single .select2-selection__arrow {
    height: 26px !important;
    position: absolute !important;
    top: 10px !important;
    right: 1px !important;
    width: 20px !important;
}
.select2-selection--single {
  border: 0 !important;
}

.select2:focus ,.select2-selection__rendered:focus, .select2-selection--single:focus  {
  outline: 0 !important;
}


.contact-form {
    background-color: white;
    width: 90%;
    max-width: 1140px;
    margin: 70px auto;

    &__container {
        display: flex;
    }

    &__form {
        width: $base-width/2;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: center;
        padding-right: 13rem;
        margin-left: auto;

        .contact-form__form--container {
            width: 100%;
        }

        h2 {
            text-transform: uppercase;
        }

        .fui-alert {
            font-size: 1.5rem;
            padding: 2rem;
            margin-top: 1rem;
        }

        form {
            padding-top: 25px;
            font-style: normal;
            .button {
                background-color: #000;
                color: #fff;
                width: 100%;
                margin-top: 30px;

            }

            .fui-input, .fui-select {
                font-size: 1.5rem;
            }

            .fui-input {
                border: 0px;
                border-bottom: 2px solid #a2a2a2;
                padding: .8rem .75rem;

                &:focus,
                &:hover {
                    border-bottom-color: #d5001b;
                    box-shadow: none;
                }
            }

            .fui-label, .fui-legend {
                position: absolute;
                width: 1px;
                height: 1px;
                padding: 0;
                margin: -1px;
                overflow: hidden;
                clip: rect(0, 0, 0, 0);
                white-space: nowrap;
                border-width: 0;
            }

            .fui-submit {
                background-color: #000;
                color: #fff;
                width: 100%;
                padding: 2rem 5rem;
                font-size: 1.4rem;
                line-height: 1;
                text-align: center;
                font-family: museo-sans,sans-serif;
                font-weight: 900;
                display: inline-block;
                text-decoration: none;
                cursor: pointer;
                transition: all .25s ease-in;
                text-transform: uppercase;
                border: 0;
            }
        }
    }

    &__image {
        width: 50%;

        img {
            height: 90.5rem;
            width: 100%;
            object-fit: cover;
            object-position: center;
            font-family: 'object-fit: cover;object-position: center;';
            border-radius: 5px;
            box-shadow: 10px 10px 30px #b5b5b5;
        }
    }
    select {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        background-image: url(../../../img/arrow-red.png);
        background-repeat: no-repeat;
        background-position: 100% 48%, 100% 48%;
        height: 40px;
        border-radius: 0px;
        color: #A2A2A2;
        font-style: normal;
        option {
            color: #000;
        }
    }
}

@include media ('<=tablet') {
    .contact-form {
        &__container {
            flex-direction: column;
            padding: 6rem 0 0rem;
        }

        &__form {
            width: calc(100% - 4rem);
            margin: 0 2rem;
            display: block;
            padding-bottom: 60px;
            padding-right: 1rem !important;
        }

        &__image {
            width: 100%;

            img {
                height: 300px;
            }
        }
    }
}
