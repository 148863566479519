/**
 * Header styling
 */
@import '../../../css/variables';

// There is default styling for h1,h2,h3,h4,h5,h6 in typography.scss
// This is only for class based overrides

$header-colors: (
  'dark': $color-dark-gray,
  'white': #fff
);

$header-line-colors: (
  'dark': $color-red,
  'white': $color-dark-gray
);

h1, h2, h3, h4, h5, h6 {
  position: relative;
  line-height: 1.3;
  letter-spacing: .4px;
  .header-icon{
      width: 79px;
      height: 79px;
      svg {
          width: 79px;
          height: 79px;
      }
      position: absolute;
      left: -30px;
  }
  @each $color, $hex in $header-colors {
    &.header--color-#{$color} {
      color: $hex;

      .header-icon{
          svg {
              color: $hex;
          }
      }

      &.animation {
        span {
          background-image: linear-gradient(map-get($header-line-colors, $color), map-get($header-line-colors, $color));
          background-position: 0% 80%;
          background-repeat: no-repeat;
          background-size: 0 1rem;
          transition: background-size 0.5s;
          transition-delay: 0.75s;
        }

        &.in-view {
          span {
            background-size: 80% 1rem;
          }
        }
      }
    }
  }

  @each $color, $hex in $colors {
    &.header--color-#{$color} {
      color: $color-dark-gray;

      &.product-header-white {
        color: #fff;
      }

      .header-icon {
          svg {
              color: $hex;
          }
      }

      &.animation {
        span {
          background-image: linear-gradient($hex, $hex);
          background-position: 0% 84%;
          background-repeat: no-repeat;
          background-size: 0 1rem;
          transition: background-size 0.5s;
          transition-delay: 0.75s;
        }

        &.in-view {
          span {
            background-size: 84% 1rem;
          }
        }
      }
    }
  }
  &.header--color-white {
      color: white;
  }

  &.has-icon {
    padding-left: 7rem;
    background-repeat: no-repeat;
    background-position: left top;
    background-size: 5rem;
    margin-bottom: 3rem;
  }
}

// FIND SOMEWHERE ELSE TO PUT THESE
.product-category--header {
  font-size: 1.4rem;
}

.title-repeating-text--header {
  font-family: 'Museo Sans', sans-serif;
  font-weight: 600;
  font-size: 3rem;
  line-height: 3.4rem;
  color: #fff;
  text-transform: uppercase;
}

.awards-item-text--header {
  font-family: 'Museo Sans', sans-serif;
  font-weight: 700;
  font-size: 2rem;
  line-height: 2.6rem;
}

.product-small-header {
  font-size: 3.8rem;
  line-height: 4.3rem;
}

//Override for the Warranty Page
//to have blue underlines instead
#block-20407{
  h3.header--color-red {
    span {
      background-image: linear-gradient($color-section-blue, $color-section-blue);
    }
  }
}

@include media ('<tablet') {

  header {
    height: 6rem;
    justify-content: space-between;
    align-items: center;
    padding: 0 2rem;

    .logo.mobile {
      display: block !important;
    }
  }
  .awards-item-text--header {
    font-size: 2rem;
    line-height: 2.6rem;
  }

  .title-repeating-text--header {
    font-size: 2rem;
    line-height: 2.6rem;
  }
  h1 {
    margin: 6rem 0 3rem;
  }
}
