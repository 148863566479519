@import '../../../css/variables';

.products-with-images-block {
    display: flex;
    background: #fff;
    width: 90%;
    max-width: 1140px;
    margin: 70px auto;
    .product-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 13rem 0;
    }
}

.mobile-header {
  display: none;
}

.products-with-images-block > div {
    width: 50%;
}


.products-with-images-block .product-category {
    background-color: white;
    position: relative;
    width: 100%;
    &:after {
        content: '';
        position: absolute;
        right: -46px;
        top: 0;
        bottom:0;
        width: 0;
        height: 0;
        border-left: 23px solid #000;
        border-right: 23px solid transparent;
        border-top: 23px solid transparent;
        border-bottom: 23px solid transparent;
        z-index: 1;
        opacity: 0;
        transition: all 250ms ease-in;
    }

    @each $color, $hex in $colors {
        &.product-category--color-#{$color}:after {
            border-left: 23px solid $hex;

        }
    }
}

.products-with-images-block .product-category {
    &:hover:after,
    &:focus:after,
    &:active:after,
    &.active:after {
        opacity: 1;
    }
}

.products-with-images-block .product-category--wrapper {
    width: 100%;
    justify-content: normal;
    text-transform: uppercase;
    font-weight: 300;
    margin: 0 0 0 auto;
    width: 57rem;
    max-width: 57rem;
}

.products-with-images-block .product-category--arrow, .facilities .product-category--arrow {
    right: -5px !important;
    left: auto !important;
    z-index: 9;
}

.products-with-images-block .pointer{
    width: 33px;
    height: 33px;
    position: absolute;
    right: -16.5px;
    top: 7px;
    transition: all 300ms ease-in;
    opacity: 0;
    transform: translateX(-150px) rotate(45deg);
}


.products-with-images-block .product-image {
    flex-direction: column;
    justify-content: flex-end;
    background-size: cover;

    p {
        width: 100%;
        color: white;
        margin: 25px 0;
        line-height: 20px;
        display: block;
    }

    picture img,
    img {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        font-family: 'object-fit: cover;';
        filter:brightness(1.2);

    }
}

.products-with-images-block .icon-wrapper-header{
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
    max-width: 500px;
    .icon-header{
        display: block;
        width: calc(100% - 30px);
    }
}
.products-with-images-block .icon-wrapper {
  position: relative;
  .description {
    max-width: 400px;
  }
}
.products-with-images-block .icon-container{
    margin: 0 20px 0 0;

    svg {
        width: 78px;
        height: auto;
    }

    @each $color, $hex in $colors {
        &.icon--color-#{$color} {
            color: $hex;
        }
    }
}
#icon-img {
    width: 78px;
    height: auto;
}

.product-content--wrapper {
    margin-left: auto;
    width: 57rem;
    p {
        font-weight: bolder;
    }

    h2,h3,h4,h5 {
        margin-bottom: 1rem;
    }

    @each $color, $hex in $colors {
        &.product-header-color-#{$color} {
            h2 span,h3 span,h4 span,h5 span {
                background-image: linear-gradient($hex, $hex);
            }
        }
    }
}

.products-with-images-block .product-content p{
    font-family: $font-family-body;
    font-size: 16px;
    max-width: 300px;
    font-weight: 300;
}

.products-with-images-block {
    .product--categories-container {
        position: relative;
        overflow: hidden;
        background-size: cover;
        background-image: url('../../../img/main-cat.jpg');
        background-attachment: fixed;
        box-shadow: 10px 10px 30px #b5b5b5;
        border-radius: 5px;
        .product-image {
            position: absolute;
            top:0;
            right:0;
            left:0;
            bottom:0;
            opacity: 0;
            visibility: hidden;
            transition: all 250ms ease-in;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            padding-bottom: 20%;

            &__container {
                max-width: 400px;
            }
            img {
                border-radius: 5px;
                box-shadow: 10px 10px 30px #b5b5b5;
                overflow: hidden;
            }
            &.active{
                opacity: 1;
                visibility: visible;
            }
            .product-overlay {
                width: 100%;
                position: absolute;
                bottom: 0;
                height: 100%;
                left: 0;
                right: 0;
                pointer-events: none;
                background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.4), black);
                z-index: 0;
                border-radius: 5px;
            }
        }
    }
}

@include media('<=tablet') {
    .mobile-header {
      display: block;
      padding: 0 20px;
      width: 100% !important;
      text-align: left;
        margin: 2rem 0;

        h1,h2,h3,h4,h5,h6 {
            margin-bottom: 1.5rem;
        }
    }

    .products-with-images-block {
        display: block;
        width: 100%;
        position: relative;
        .product-content {
            display: none;
        }
        .icon-wrapper{
            margin: 0;
        }

        .product--categories-container {
            display: block;
            width: 90%;
            margin: 0 auto;
            background: none;
            .icon-container {
                display: none;
            }

            p, a {
                display: none;
            }
            .visible {
              display: block !important;
            }

            .product-image {
                position: relative;
                height: 168px;
                top:auto;
                right:auto;
                left:auto;
                bottom:auto;
                opacity: 1;
                visibility: visible;
                text-align: center;
                margin: 5px 0;
                display: flex;
                justify-content: center;
                align-items: center;
                background-size: cover;
                background-position: center;
                padding: 1rem !important;

                picture img {
                    position: absolute;
                    left: 0;
                    right: 0;
                    top: 0;
                    bottom: 0;
                    width: 100%;
                    height: 100%;
                }

                @each $color, $hex in $colors {
                    &.product--color-#{$color} {
                        h3 {
                            border : 4px solid $hex;
                        }
                    }
                }
                .product-overlay {
                    display: block;
                    width: 100%;
                    height: 100%;
                    background: black;
                    position: absolute;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 0;
                    opacity: 0.6;
                    z-index: 0;
                }
            }
            .icon-header, h3 {
                display: block;
                width: 100%;
            }
            h3 {
                font-size: 24px;
                width: 50%;
                margin: 0 calc(50% - 100px);
                padding: 15px 25px;
                border : 4px solid $color-red;
                border-radius: 5px;
                position: relative;
                z-index: 0;
            }
        }
        .product-image.main-cat {
            display: none;
        }
    }

    .product-image__container {
        width: 100%;
    }

    .products-with-images-block .product--categories-container .visible {
        width: 100%;
    }

    .products-with-images-block .product--categories-container .icon-header {
        display: flex;
        justify-content: center;
    }

    .products-with-images-block .product--categories-container h3 {
        width: 50vw;
    }

    .products-with-images-block .icon-wrapper-header {
        max-width: 100%;
    }

    .products-with-images-block .product--categories-container h3 {
        margin: 10px 0;
    }
}

@include media('<smallPhone') {
    .products-with-images-block .product--categories-container h3 {
        font-size: 2rem;
    }

    .products-with-images-block .product--categories-container h3 {
        width: 75vw;
    }
}