.search--page {
  max-width: $base-width;
  margin: 0 auto;
  padding-top: 100px;

  input[type="search"]::-webkit-search-decoration,
  input[type="search"]::-webkit-search-cancel-button,
  input[type="search"]::-webkit-search-results-button,
  input[type="search"]::-webkit-search-results-decoration {
    display: none;
  }

  h1 {
    font-size: 8rem;
    margin: 0 0 3rem;
  }

  .ais-Menu {
    ul {
      list-style: none;
      margin: 0 -1rem;
      padding: 0;
      display: flex;

      li {
        margin: 0 1rem;
      }
    }
  }

  .ais-SearchBox {
    margin: 0 0 3rem;

    form {
      display: flex;
    }

    .ais-SearchBox-input {
      background: url('../../img/search-black.svg') no-repeat left center;
      padding: 1rem 0 1rem 5rem;
      font-size: 2rem;
      color: $color-dark-gray;
      border-bottom: 2px solid $color-medium-gray;
      outline: none;
      flex: 1;
    }

    button {
      display: none;
    }
  }

  .ais-ClearRefinements {
    margin: 2rem 0;
    text-align: right;
  }

  .ais-StateResults {
    margin: 4rem auto;
  }

  .search--result-header {
    margin-bottom: 1rem;
  }

  .search--result-description table {
    float: none !important;
  }
}

.document, .catalog {
  width: 24%;
  text-align: left;
  display: flex;
  align-items: center;
  border: 1px solid #efefef;
  padding: 15px;
  margin: 10px 0;
  min-height: 100px;

  .pdf {
    width: 45px;
    position: relative;

    .bar {
      position: absolute;
      width: 38px;
      font-weight: bold;
      z-index: 1;
      padding: 1px 5px 2px;
      border-radius: 5px;
      top: 19.5px;
      color: white;
    }
  }

  .filename {
    width: calc(100% - 55px);
    margin: 0 0 0 10px;
    text-align: left;
    font-weight: bold;
  }
  @include media('<tablet') {
    width: 100%;
  }
}

.catalog {
  width: 32%;
  @include media('<tablet') {
    width: 100%;
  }
}

.tabs-component {

  > ul {
    padding: 3px;
    display: flex;
    border-radius: 30px;
    background: #f6f6f6;
    margin-bottom: 50px;
    justify-content: space-between;
    li {
      list-style-type: none;
      width: 33%;
      border-radius: 30px;
      padding: 10px 0;
      font-size: 2rem;
      font-weight: bold;
      text-align: center;
      &.is-active {
        background: white;
        box-shadow: 1px 1px 1px #dcdcdc;
      }
    }
  }

  @include media('<tablet') {
    ul {
      flex-direction: column;
      li {
        width: 100%;
      }
    }
  }
}
.min-h-full {
  min-height: 55vh;
}

.m-between {
  margin: 10px 0.5%;
}
.sheetTabs {
  border: 2px solid black;
  display: flex;
  margin: 0 0 50px 0;
  button {
    width: 50%;
    padding: 10px 0;
    text-align: center;
    font-size: 1.5rem;
    font-weight: bold;
    &.is-active {
      background: black;
      color: white;
    }
  }
}