/**
 * Footer styling
 */
@import '../../../css/variables';

.footer {
  background-color: $color-dark-gray;
  padding: 7.5rem 2rem 6rem;
  color: #fff;
  font-family: $font-family-menu;
  font-weight: 300;
  font-size: 1.4rem;
  line-height: 2.1rem;

  .logo-mobile {
    display: none;
    margin-bottom: 25px;
  }


  .smallHeader span{
    text-decoration: none !important;
    font-size: 1rem !important;
  }

  .footer__container {
    max-width: $base-width;
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
  }

  .social-icons {
    padding-top: 20px;
    svg {
      display: inline-block;
      width: 3rem;
      height: 3rem;
    }
    .vimeo {
      display: inline-block;
      width: 2.6rem;
      height: 2.6rem;
    }
  }

  .logo svg {
    width: 22rem;
    height: auto;
  }

  a {
    text-decoration: none;
    color: #fff;
  }

  ul {
    margin: 0;
    font-style: normal;
    line-height: 1.5;
  }

  li {
    padding-bottom: 1rem;
    list-style-type: none;

    a {
      text-transform: uppercase;
      font-weight: 700;
    }
  }

  .footer__col1 a {
    padding-right: 2rem;
  }

  .footer__col1 img {
    padding-bottom: 2rem;
  }

  .footer__col3 {
    display: flex;
    flex-direction: column;
  }

  .copyright {
    display: flex;
    justify-content: flex-end;
    max-width: 118.5rem;
    width: 100%;
    margin: 0 auto;
    padding-top: 2rem;
  }

  .smallHeader strong{
      font-size: 1.3rem;
      font-weight: 700;
  }
}

.mobile-line {
  display: none;
}

@include media ('<phone') {
  .mobile-line {
    display: block;
    width: 90%;
    margin: 25px 5% 0;
    height: 1px;
    background: #dbdbdb;
  }
  .footer {
    background-color: #fff;
    color: $color-dark-gray;
    padding: 3.5rem 2rem 2rem;
    a {
      color: $color-dark-gray !important;
    }
    .footer__container {
      flex-direction: column;
      ul {
        padding-left: 0;

        a {
          color: $color-dark-gray !important;
        }
      }
    }

    .logo-mobile {
      display: block;
    }



    .social-icons {
      padding-top: 10px;

      .linkedin svg path {
        fill: #0e76a8;
      }
    }

    .footer__col1 {
      order: 3;

      .logo {
        display: none;
      }
    }

    .footer__col2 {
      order: 0;
    }

    .footer__col1,
    .footer__col2,
    .footer__col3 {
      padding-bottom: 3.5rem;
    }

    .copyright {
      justify-content: flex-start;
    }
  }
}
