/**
 * Downloads styling
 */
@import '../../../css/variables';

.safety-data-sheet {
  background: $color-gray;

  &--container {
    max-width: $base-width;
    margin: 0 auto;
    padding: 5rem 0;

    h1,h2,h3,h4,h5,h6 {
      margin-bottom: 2rem;
    }
  }

  &--buttons {
    margin-top: 2rem;

    a {
      margin-bottom: 1rem;
      display: block;
    }
  }
}

@include media('<=tablet') {
  .safety-data-sheet {
    &--buttons {
      grid-template-columns: repeat(3, 1fr);
    }
  }
}

@include media ('<phone') {
  .safety-data-sheet {
    &--container {
      padding: 5rem 2rem;
    }

    &--buttons {
      grid-template-columns: repeat(2, 1fr);
    }
  }
}

@include media ('<smallPhone') {
  .safety-data-sheet {
    &--buttons {
      grid-template-columns: repeat(1, 1fr);
    }
  }
}
