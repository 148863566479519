/**
 * Slider styling
 */
@import '../../../css/variables';

.slider {
  height: 100%;
  min-height: 100% !important;
  .slick-slide > div {
    line-height: 0;
  }

  .slick-track {
    height: 100%;
  }
  button {
      transition: 0.2s
  }

  .slick-active {
      button {
          transform: scale3d(0.8,0.8,0.8);
      }
  }

  @each $color, $hex in $colors {
      .slider--color-#{$color} {
          .slick-active {
              button {
                  background: #{$color} !important;
              }
          }
      }
  }

  .slick-dots {
    list-style:none;
    margin:0;
    padding:0;
    position: absolute;
    bottom:4rem;
    left:0;
    right:0;
    display: flex;
    justify-content: center;

    li {
      margin: 0 1rem;

      button {
        background: $color-dark-gray;
        width: 2rem;
        height: 2rem;
        border-radius: 100%;
        font-size: 0;
        color: transparent;
        outline: none;
      }
    }
  }

  @each $color, $hex in $colors {
    &.slider--color-#{$color} {
      .slick-dots li.slick-active button,
      .slick-dots li button:hover,
      .slick-dots li button:focus,
      .slick-dots li button:active {
        background: $hex;
      }
    }
  }
}
