.history {
    &__container.sticky {
        .history__links {
            position: fixed;
            top: 60px;
            width: 100%;
        }

        .history__boxes {
            padding-top: 146px;
        }
    }

    .hidden {
        display: block;
        position: relative;
        top: -146px;
        visibility: hidden;
    }

    &__container {
        max-width: 114rem;
        width: 100%;
        margin: 0 auto;
        padding-top: 3rem;
        padding-bottom: 7rem;

        h2 {
            padding-bottom: 2.5rem;
        }
    }

    &__links {
        padding-top: 2rem;
        display: flex;
        margin: 0px -1rem;
        flex-wrap: wrap;
        background-color:#fff;
        z-index: 1;
        .active {
          background: $color-red;
          color: white;
          border: 2px solid $color-red;
        }

        &--link {
            margin: 0px 1rem 1.5rem;
            padding: 1.3rem 1.8rem;
            border: 0.2rem solid #A2A2A2;
            font-family: 'Museo Sans', sans-serif;
            font-weight: 900;
            text-decoration: none;
            color: #A2A2A2;
            transition: all 0.2s ease-in;
            font-size: 1.4rem;

            &:hover {
                border: 0.2rem solid $color-red;
                color: white;
                background-color: $color-red;
            }
        }
    }

    &__boxes {
        padding-top: 4.5rem;
        opacity: 0;
        transition: all 250ms ease-in;

        &.finished {
            opacity: 1;
        }
    }

    &__item {
        display: flex;
        padding-bottom: 3rem;

        &--image {
            width: 37%;
            flex: 0 1 auto;
            background-repeat: no-repeat;
            background-position: center;
            background-image: url('../../../img/bg-example.jpg');
        }

        &--text {
            width: 63%;
            display: flex;
            justify-content: center;
            flex-direction: column;
            background-color: grey;
            background-image: url('../../../img/granite-bg.jpg');
            background-size: cover;

            &-container {
                padding-left: 7.5rem;
                padding-right: 7.5rem;
                padding-top: 9rem;
                padding-bottom: 8rem;
                color: #fff;
            }

            &-header {
                display: flex;
                padding-bottom: 25px;
                align-items: center;
            }

            .text-year {
                font-size: 9rem;
                font-family: 'Anton', sans-serif;
                line-height: 8rem;
                border-right: 0.8rem solid red;
                padding-right: 2.5rem;
            }

            .text-title {
                display: flex;
                align-items: center;
                padding-left: 2.5rem;
                color: #fff;
            }
        }
    }

    //History Box Slide in animation
    .add-in-view-on-view {
        opacity: 0;
        transition: all .6s ease-out;
    }
    .align-left.add-in-view-on-view {
        transform: translateX(-30%);
    }
    .align-right.add-in-view-on-view {
        transform: translateX(30%);
    }
    .add-in-view-on-view.in-view {
        opacity: 1;
        transform: translateX(0%) scale(1);
    }
}

@include media ('<desktop') {
    .history {
        &__container {
            padding: 0 20px;
            flex-direction: column;
        }

        &__item {
            flex-direction: column;

            &--image {
                height: 40rem;
                width: 100%;

                img {
                    height: 40rem;
                    width: 100%;
                }
            }

            &--text {
                width: 100%;
            }
        }
    }
}

@include media ('<phone') {
    .history {
        padding: 0;

        &__container {
            padding: 3rem 2rem;
        }

        &__links {
            &--link {
                font-size: 1.4rem;
            }
        }

        &__item {
            &--image {
                height: 27rem;
            }

            &--text {
                width: 100%;

                &-container {
                    padding: 2.5rem;
                }

                &-header {
                    .text-year {
                        font-size: 3.2rem;
                        line-height: 3.2rem;
                    }
                }
            }
        }

        &__container.sticky {
            .history__boxes {
                padding-top: 212px;
            }
        }

        .hidden {
            display: block;
            position: relative;
            top: -212px;
            visibility: hidden;
        }
    }
}
