.usa-canada-block {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    > div{
        width: 50%;
    }

    p {
        color: #fff;
        padding-top: 1.5rem;
        max-width: 375px;
    }

    .right-content, .left-content {
        height: 467px;
        position: relative;
        overflow: hidden;

        .usa-canada-block-wrapper {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
            height: 100%;
            position: relative;
        }

        .usa-canada-block-background {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            transition: all 150ms ease-in;
        }

        &:hover {
            .usa-canada-block-background {
                transform: scale(1.03);
            }
        }
    }

    .left-content .usa-canada-block-background{
        background-image: url('../../../img/canada.jpg');
        background-size: cover;
    }

    .right-content .usa-canada-block-background {
        background-image: url('../../../img/usa.jpg');
        background-size: cover;
    }

    .button {
        background: white;
        color: black !important;
        border-radius: 3px;
        padding: 17px 35px;
        margin: 25px 0;
    }
}

@media(max-width: 992px){
    .usa-canada-block {
        flex-direction: column;
        padding: 0;
        margin: 0;
        .left-content,
        .right-content {
            height: 32rem;
        }

        > div{
            width: 100%;
        }
    }
}
