/**
 * ar block styling
 */
@import '../../../css/variables';

.ar-product-viewer {
  display: flex;
  position: relative;
  width: 90%;
  max-width: 1140px;
  margin: 70px auto;
  background-color: #fff;
  transition: all 0.3s ease;
  overflow: visible;
  box-shadow: 0 0px 0px -0px rgb(0, 0, 0);

  vctr-viewer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .content {
    flex: 1;
    display: flex;
    align-items: center;
  }

  .viewer-wrapper {
    width: 100%;
    height: 100%;
    position: relative;
  }

  .sidebar {
    flex: 0 2 100%;
    max-width: 380px;
    background-color: #F5F5F5;
    padding: 35px;
  }

  h3 {
    margin: 2rem 0;
    background: none;
  }

  h5 {
    background: 0;
    margin: 0 0 1rem;
  }

  h6 {
    font-size: 1.5rem;
  }

  sub {
    font-size: 1.8rem;
    margin-bottom: 0rem;
    font-weight: 500;
    display: block;
    text-transform: uppercase;
    position: relative;
  }

  .category {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 2rem;
    margin-left: 1rem;
    text-align: center;
  }

  .subcategory_wrapper {
    margin-bottom: 1rem;
  }
  .subcategory_wrapper sub {
    font-size: 1.2rem;
    margin-top: 0.5rem;
    margin-left: 1rem;
  }
  .subcategory_wrapper .category {
    margin-bottom: 1rem;
  }

  .item {
    position: relative;
    cursor: pointer;
    padding: 1.5rem 0;
    display: flex;
    width: 30%;
    justify-content: space-evenly;
  }
  .item.active .clr:after {
    opacity: 1;
  }
  .item:hover:not(.active) .clr:after {
    opacity: 0.3;
  }

  .item img,
  .item svg {
    opacity: 0.3;
    height: 5rem;
  }
  .item.active img,
  .item.active svg {
    opacity: 1;
  }
  .item:hover:not(.active) img,
  .item:hover:not(.active) svg {
    opacity: 0.5;
  }
  .item img,
  .item svg {
    pointer-events: none;
    transition: all 0.3s ease;
  }
  .category.colors .item {
    padding: 2rem 0;
  }

  .clr {
    position: relative;
    width: 26px;
    height: 26px;
    border-radius: 13px;
    pointer-events: none;
    background-color: #545454;
  }

  .clr:after {
    position: absolute;
    content: "";
    top: -5px;
    right: -5px;
    bottom: -5px;
    left: -5px;
    opacity: .1;
    display: block;
    border-radius: 100%;
    box-shadow: 0 0 0 2px #000;
    transition: all 0.3s ease;
  }

  .clr-finish-pewter {
    background-image: url(/images/pewter.jpg);
  }

  .clr-finish-brown {
    background-image: url(/images/brown.jpg);
  }

  .clr-finish-white-steel {
    background-image: url(/images/white_steel.jpg);
  }

  .clr-finish-black-iron {
    background-image: url(/images/black_iron.jpg);
  }

  .clr-finish-granit {
    background-image: url(/images/Grey_Granit.jpg);
  }

  .clr-finish-bronze-age {
    background-image: url(/images/Bronze_Age.jpg);
  }

  .clr-finish-matte-black {
    background-image: url(/images/Matte_Black.jpg);
  }

  .clr-finish-black {
    background-image: url(/images/Black.jpg);
  }

  .clr-finish-white-polystyrene {
    background-image: url(/images/white_steel.jpg);
  }

  .clr-finish-brushed-nickel {
    background-image: url(/images/Brushed_Nickel.jpg);
  }

  .info {
    display: block;
    position: absolute;
    width: 200px;
    border-radius: 5px;
    background-color: white;
    padding: 20px;
    opacity: 0;
    transition: opacity .25s ease-in-out;
    box-shadow: 0px 0px 4px -1px rgba(0, 0, 0, 0);
    z-index: 1;
  }

  .info span {
    font-weight: 500;
    font-size: 1.5rem;
    display: block;
    margin-bottom: 10px;
  }

  .info img {
    width: 100%;
  }

  .summary {
    display: flex;
    justify-content: space-between;
    margin-top: 4rem;
  }

  .vctr-hidden {
    display: none;
  }
}

@include media('<=tablet') {
  .ar-product-viewer {
    flex-direction: column;
    max-height: unset;
    margin: 4rem auto;

    .viewer-wrapper {
      padding-bottom: 55%;
    }
    .sidebar {
      max-width: 100%;
      padding: 30px;
    }
    .category {
      grid-template-columns: 25% 25% 25%;
    }
  }
}

@include media('<phone') {
  .ar-product-viewer {
    border-radius: 0;

    .back {
      display: none;
    }
    .category {
      margin-bottom: 0;
    }
    .summary {
      margin-top: 2rem;
    }
    .category {
      grid-template-columns: 45% 45%;
    }
  }
}

.desktop-product-viewer {
  width:100%;
}

.mobile-product-viewer {
  display: none;
}

@media(max-width: 1023px){
  .desktop-product-viewer {
    display: none;
  }
  .mobile-product-viewer {
    display: block;
    width:100%;
  }
}
