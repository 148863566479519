/**
 * Spin styling
 */
@import '../../../css/variables';

.spin {
  background: #fff;

  .spin--text-wrapper {
    margin: 3rem auto 0;
  }

  .header--wrapper {
    width: 100%;
    max-width: 114rem;
    margin: 0 auto;
  }

  .spin--wrapper {
    width: 100%;
    max-width: 120rem;
    margin: 0 auto;
    display: flex;
    flex-flow: column;
    align-items: center;
  }

  .spin--product-spin {
    width: 60rem;
    height: 60rem;

    picture, img {
      outline: none !important;
    }
  }

  .spin--options {
    display: flex;
    flex-wrap: wrap;

    .spin--option {
      position: relative;
      list-style: none;
      text-align: center;
      cursor: pointer;
      flex-grow: 1;
      font-size: 1.6rem;
      font-family: "Museo Sans", sans-serif;
      font-style: normal;
      font-weight: 900;
      margin: 0 1rem;
      outline: none;
      color: #A2A2A2;
      transition: all 250ms ease-in;
      line-height: 1.5;
      padding-bottom: 5px;

      &.active {
        color: #262626;
      }

      &:after {
        content: "";
        position: absolute;
        width: 100%;
        height: 4px;
        background: #A2A2A2;
        left: 0;
        bottom: 0;
        transition: all 250ms ease-in;
      }

      @each $color, $hex in $colors {
        &.spin--color-#{$color}.active:after,
        &.spin--color-#{$color}:hover:after,
        &.spin--color-#{$color}:focus:after {
          background: $hex;
        }
      }
    }
  }
}

@include media('<=tablet') {
  .spin {
    .spin--text-wrapper {
      padding-left: 2rem;
    }
  }
}

@include media('<phone') {
  .spin {
    .spin--text-wrapper {
      padding: 2rem;
    }

    .spin--product-spin {
      width: 30rem;
      height: 30rem;
    }
    .spin--options {
        flex-direction: column;
        width: 100%;
        .spin--option{
            margin: 8px 0;
        }
    }
  }
}
