/**
 * Specifications styling
 */
@import '../../../css/variables';

.product--specifications {
  background: #fff;
  padding: 10rem 0;

  .tabs--container {
     li {
      font-size: 1.6rem;
      padding-bottom: 5px;
    }
  }

  .product--specifications-container {
    max-width: $base-width;
    margin: 0 auto;

    h2,h3,h4,h5,h6 {
      margin-bottom: 5rem;
    }
  }

  .specifications--tab-group {
    background: $color-gray;
    padding: 4rem;
    margin-bottom: 2rem;
    display: flex;
    font-size: 1.4rem;
    font-family: $font-family-body;
    line-height: 1.5;

    .specifications--tab-group-left {
      flex: 1;
    }

    .specifications--tab-group-right {
      margin-left: 2rem;
      width: 20rem;
    }



    table {
      width: 100%;

      thead {
        td, th {
          padding: 1rem 0;
          font-size: 1.8rem;
          color: $color-dark-gray;
          border-bottom: 3px solid $color-dark-gray;
        }
      }

      tbody tr td {
        border-bottom: 1px solid $color-medium-gray;
        padding: 1rem 0;
        font-size: 1.8rem;
        color: $color-dark-gray;

        p {
          margin: 0;
          font-size: 1.8rem;
          color: $color-dark-gray;
          line-height: 2.7rem;
          font-weight: 400;
        }
      }

      tbody tr:last-child td {
        border-bottom: 0;
      }
    }
  }

  .button {
    margin-top: 2rem;
  }
}

@include media('<=tablet') {
  .product--specifications .product--specifications-container h2, .product--specifications .product--specifications-container h3, .product--specifications .product--specifications-container h4, .product--specifications .product--specifications-container h5, .product--specifications .product--specifications-container h6 {
    margin-left: 2rem;
  }
}

@include media ('<smallPhone') {
    .product--specifications {
        table {
          tbody tr td {
            width: 50%;
            vertical-align: top;
            &:first-child {
              font-weight: bold;
              white-space: nowrap;
            }
          }
        }
    }
}
