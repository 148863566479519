/**
 * Tabs styling
 */
@import '../../../css/variables';

.tabs--container {
  overflow: hidden;

  [role=tablist] {
    display: flex;
    margin: 0 -2rem;
    padding: 0;
    flex-wrap: wrap;
  }

  [role=tab] {
    position: relative;
    list-style: none;
    text-align: center;
    cursor: pointer;
    flex-grow: 1;
    font-size: 2.8rem;
    font-family: $font-family-body;
    font-style: normal;
    font-weight: 900;
    margin: 0 1rem;
    outline: none;
    color: $color-medium-gray;
    transition: all 250ms ease-in;
    line-height: 1.5;

    &::after {
      content: "";
      position: absolute;
      width: 100%;
      height: 4px;
      background: $color-medium-gray;
      left: 0;
      bottom: 0;
      transition: all 250ms ease-in;
    }

    @each $color, $hex in $colors {
      &.tab--color-#{$color} {
        &[aria-selected=true]::after {
          background: $hex;
        }

        &:focus:after,
        &:hover:after,
        &:active:after {
          background: $hex;
        }
      }
    }
  }

  [role=tab][aria-selected=true] {
    color: $color-dark-gray;
  }

  [role=tab][aria-selected=true]::after {
    background: $color-dark-gray;
  }

  [role=tab]:hover,
  [role=tab]:focus {
    color: $color-dark-gray;
    &:after {
      background: $color-dark-gray;
    }
  }

  [role=tabpanel] {
    display: none;
  }

  [role=tabpanel][aria-expanded=true] {
    display: block;
  }
}
