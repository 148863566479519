/**
 * Facilities styling
 */
@use "sass:map";
@import '../../../css/variables';

.facilities {
  width: 90%;
  max-width: 1140px;
  margin: 70px auto;
  .facilities--wrapper {
    display: flex;
    flex-direction: row;

    .facilities--left,
    .facilities--right {

        width: 50%;
        flex: 1 1 auto;
        position: relative;
        .black-overlay {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background: linear-gradient(180deg, rgba(0,0,0,0) 15%, rgba(0,0,0,1) 100%);
            z-index: 1;
        }
        picture img {
          object-fit: cover;
          object-position: center;
          font-family: 'object-fit: cover;object-position: center;';
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 100%;

        }
        .bigger-text{
            margin-top: 1.2rem;
            font-size: 2rem;
        }

        .map--details {
            width: 90%;
            margin: 0 auto;
            max-width: 400px;
            color: white;
            position: relative;
            z-index: 2;
            padding-bottom: 8rem;
            > div {
                display: block;
                margin-bottom: 1.5rem;
            }
            > button {
                margin-top: 1.5rem;
            }
        }
    }

    .facilities--right {
      border-radius: 5px;
      box-shadow: 10px 10px 30px #b5b5b5;
      overflow: hidden;
    }

    .facilities--left {
      padding: 8rem 0;
      h2,
      h3,
      h4,
      h5 {
        width: $base-width/2;
        margin-left: auto;
        margin-bottom: 3rem;
      }
    }
  }

  .facilities--map {
    width: 100%;
    height: 100%;
    position: relative;
    .map--images{
      transition: 0.3s;
      width: 100%;
      height: 100%;
      background-size: cover;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      opacity: 1;
      &.active{
        opacity: 1 !important;
      }
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: flex-start;
    }
  }

  .product-category {
    position: relative;
    cursor: pointer;

    .product-category--wrapper {
      max-width: 100%;
      width: $base-width/2;
      margin: 0 0 0 auto;
    }

    &:after {
      content: '';
      position: absolute;
      right: -46px;
      top: 0;
      bottom:0;
      width: 0;
      height: 0;
      border-left: 23px solid $color-red;
      border-right: 23px solid transparent;
      border-top: 23px solid transparent;
      border-bottom: 23px solid transparent;
      z-index: 8;
      opacity: 0;
      transition: all 250ms ease-in;
    }

    &:hover:after,
    &:focus:after,
    &:active:after,
    &.active:after {
      opacity: 1;
    }
  }

  .product-category--arrow {
    top: -4px;
  }
}

@include media ('<=tablet') {
  .facilities .facilities--wrapper .facilities--left {
    padding: 8rem 5rem 8rem 2rem;
  }

  .facilities .facilities--wrapper .facilities--left .map--details, .facilities .facilities--wrapper .facilities--right .map--details {
    padding-left: 5rem;
  }
}

@include media ('<phone') {
  .facilities {
    .facilities--wrapper {
      flex-direction: column;
      padding: 4rem 0 0;
      .facilities--left {
        width: calc(100% - 4rem);
        padding: 4rem 2rem;

        h2, h3, h4, h5, h6 {
          width: auto;
          margin: 0;
          padding-bottom: 3rem;
        }
      }

      .map--images {
        align-items: center;
        justify-content: center;
      }

      .facilities--left,
      .facilities--right {
        .black-overlay {
          background: linear-gradient(180deg, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 1) 100%);
        }

        .map--details {
          padding: 0 2rem;
          max-width: 100%;
        }
      }

      .facilities--right {
        width: 100%;
        height: 40rem;
      }

      .product-category .product-category--wrapper {
        width: 100%;
      }
    }
  }
}
