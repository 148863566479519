/**
 * pages/homepage.pcss
 *
 * Styles for the Home page.
 *
 */

body, html {
  overflow-x: hidden;
  position: relative;
  width: 100%;
}

.homepage--full-width-height,
.homepage-slider--slide {
  width: 100vw;
  height: 100vh;
  max-width: 100%;
  overflow: hidden;
  position: relative;
  background: #000;
}

.homepage-slider .slick-dots {
  position: absolute;
  bottom: 0px;
  display: flex;
  gap: 2rem;
  right: 50px;
  font-size: 25px;
  padding-left: 0;

  li {
    background: #7c7c7c8a;
    border: 1px solid white;
    border-radius: 50%;
    color: transparent;
    width: 16px;
    height: 16px;

    &.slick-active {
      background: #fff;
    }
  }
}

.homepage-slider--slide {
  background-size: cover;
  display: flex;
  align-items: center;
  position: relative;
  z-index: 10;

  h1 {
    color: white;
    font-size: 3.7rem;
    line-height: 1.5;
    letter-spacing: 0.6px;
    text-shadow: 1px 1px 2px black;
    text-transform: initial;
  }

  .slide-text-image {
    display: flex;
    align-items: center;
    width: 100%;
    max-width: 114rem;
    height: 100%;
    margin: 0 auto;
    position: relative;
    z-index: 99;

    .slide-text {
      h1 {
        text-transform: uppercase;
      }

      &.large h1 {
        font-size: 5rem;
      }

      &.extraLarge h1 {
        font-size: 6.5rem;
      }

      width: 40%;
      max-width: 500px;
      margin-left: auto;
      text-align: right;
      margin-top: auto;
      margin-bottom: 150px;
      color: white;

      a {
        margin-top: 20px;
        padding: 1.5rem 3.2rem;
        color: black;
      }

      a:hover {
        background-color: black;
        color: white;
      }
    }

    img {
      width: 60%;
    }
  }

  &.reverse-order .slide-text-image {
    flex-direction: row-reverse;

    .slide-text {
      text-align: left;
      margin-right: auto;
      margin-left: 0;

    }
  }
}


.homepage--full-embed {
  position: relative;
  padding-bottom: 56.25%;
  overflow: hidden;
}

.homepage--full-embed iframe,
.homepage--full-embed object,
.homepage--full-embed embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.black-grade {
  width: 100%;
  position: absolute;
  top: 0;
  height: 20%;
  background-image: linear-gradient(rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
  left: 0;
  right: 0;
  pointer-events: none;
  z-index: 1;
}

.bottom-black-gradient {
  width: 100%;
  position: absolute;
  bottom: 0;
  height: 100%;
  left: 0;
  right: 0;
  pointer-events: none;
  background-image: linear-gradient(to top, #000000, rgba(0, 0, 0, 0));
  z-index: 1;
}

.scroll-arrow {
  width: 34px;
  height: 31px;
  background: transparent url('../../img/white-arrow.svg') no-repeat center;
  background-size: 31px;
  position: absolute;
  left: calc(50% - 15.5px);
  bottom: 50px;
  z-index: 1;
  transform: translateY(-12px);
  opacity: 0;
  animation: move-down 1.8s linear 1s infinite normal;
}

.scroll-down-arrow {
  opacity: 0;
  animation: move-down 1.8s linear 1s infinite normal;
}

@keyframes move-down {
  0% {
    transform: translateY(-12px);
    opacity: 0
  }
  50% {
    transform: translateY(0px);
    opacity: 1
  }
  100% {
    transform: translateY(12px);
    opacity: 0
  }
}

@include media('<tablet') {
  .homepage--full-width-height {
    height: auto;
  }
}

@include media('<phone') {
  .black-grade {
    height: 90px;
    opacity: 0.5;
  }
}

@include media('<phone') {
  .homepage--full-width-height {
    width: 100%;
    height: auto;
    position: static;
    max-width: none;
    overflow: auto;

    .homepage--full-embed {
      position: relative;
      padding-bottom: 56.25%;
      overflow: hidden;
      width: auto;
      height: auto;
    }
  }
}

@media(max-width: 768px) {
  .homepage-slider .slick-dots {
    right: 20px;
  }

  .homepage-slider--slide.reverse-order .slide-text-image,
  .slide-text-image {
    flex-direction: column;
    justify-content: center;

    img {
      width: 100% !important;
    }

    .slide-text {
      width: 100% !important;
      text-align: center !important;
      max-width: 100% !important;
      margin: 0 !important;

      h1 {
        margin: 0 !important;
        font-size: 3.2rem;
      }
      &.large h1 {
        font-size: 4rem;
      }

      &.extraLarge h1 {
        font-size: 5rem;
      }
    }
  }
}

