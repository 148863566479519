.two-col-title-text {
    background-color: #E8E8E8;
    &__container {
        max-width: 1140px;
        width: 100%;
        margin: 0 auto;
        padding: 8rem 2rem 8rem;
        display: flex;
        justify-content: space-between;
    }

    &__left {
        width: calc(50% - 2rem);
        padding-right: 1rem;
    }

    &__right {
        width: calc(50% - 2rem);
        padding-left: 1rem;
    }

    p {
        font-weight: 300;
        font-size: 2.2rem;
        padding-bottom: 3rem;
    }
}

@include media ('<phone') {
    .two-col-title-text {
        margin: 4rem 0;

        &__container {
            flex-direction: column;

            h2 {
                padding-bottom: 2.5rem;
            }
        }

        &__left {
            width: 100%;
            padding-right: 0;
        }

        &__right {
            width: 100%;
            padding-left: 0;
        }
    }
}
