/**
 * PhotoGallery styling
 */
@import '../../../css/variables';

.photo--gallery {
  max-width: $base-width;
  margin: 5rem auto;

  .ais-Menu {
    ul {
      list-style: none;
      margin: 0 -1rem;
      padding: 0;
      display: flex;

      li {
        margin: 0 1rem;
      }
    }
  }

  .ais-SearchBox {
    margin: 0 0 3rem;

    form {
      display: flex;
    }

    .ais-SearchBox-input {
      background: url('../../../img/search-black.svg') no-repeat left center;
      padding: 1rem 0 1rem 5rem;
      font-size: 2rem;
      color: $color-dark-gray;
      border-bottom: 2px solid $color-medium-gray;
      outline: none;
      flex: 1;
    }

    button {
      display: none;
    }
  }

  .ais-Menu ul li a {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 2px solid $color-medium-gray;
    padding: 1rem 2rem;
  }

  @each $color, $hex in $colors {
    &.button--color-#{$color} {
      color: $color-medium-gray;
      box-shadow: none;

      &:focus,
      &:hover,
      &:active,
      &.active {
        background: $hex;
        border-color: $hex;
        @if (lightness($hex) > 50) {
          color: darken($hex, 100%);
        }
        @else {
          color: lighten($hex, 100%);
        }
      }
    }
  }

  .ais-ClearRefinements {
    margin: 2rem 0;
  }

  .ais-StateResults {
    margin-top: 3rem;
  }

  .filename {
      text-align: center;
      margin: 50px 0 30px;
      font-size: 1.4rem;
      text-transform: uppercase;
  }

  .hooper {
    height: auto;
    outline: none;
  }

  .hooper-next, .hooper-prev {
    top: 128px;
  }

  .hooper-prev {
    left: 0px;
  }

  .hooper-next {
    right: 0px;
  }

  .hooper-slide img {
    margin: 0 auto;
    max-width: auto;
    height: 300px;
    outline: none;
  }

  @each $color, $hex in $colors {
    .photo--gallery-slider-color-#{$color} {
      .hooper-prev,
      .hooper-next {
        background: $hex;

        svg path:nth-child(3) {
          fill: #fff;
        }
      }
      .bar {
        background: $hex;
      }
      .document, &.catalog {
        transition: 0.2s;
        &:hover {
          background: $hex;
          color: #fff;
        }
      }
    }
  }

  .photo--gallery-slider-color-default {
    .hooper-prev,
    .hooper-next {
      background: $color-dark-gray;

      svg path:nth-child(3) {
        fill: #fff;
      }
    }
  }

  .photo--gallery-thumbnails {
    display: flex;
    flex-wrap: wrap;
    margin: 4rem -2rem;
    & > div {
      flex-basis: calc(20% - 2rem);
      margin: 1rem 1rem 0 1rem;
    }
  }

  .photo--gallery-download {
    margin: 2rem 0 5rem;
    text-align: center;
  }

  form {
    display: flex;
  }

  .missing {
    font-size: 2rem;
    text-align: center;
    font-family: "museo-sans", sans-serif;

    span {
      font-weight: 700;
      text-decoration: underline;
    }

    &:after {
      content: '.';
    }
  }
}

@supports (display: grid) {
  .photo--gallery {
    .photo--gallery-thumbnails {
      display: grid;
      margin: 4rem 0;
      grid-gap: 1rem;
      grid-auto-flow: row;
      grid-template-columns: repeat(6, 1fr);

      & > div {
        width: 100%;
        height: 100%;
        margin: 0;
        padding-bottom: 100%;
        position: relative;

        & > div {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
        }

        img {
          width: 100%;
          height: 100%;
          margin: 0 auto;
          object-fit: contain;
          object-position: center;
        }
      }
    }

    .photo--gallery-categories {
      display: grid;
      grid-gap: 1rem;
      grid-auto-flow: row;
      grid-template-columns: repeat(6, 1fr);
      margin: 2rem 0;

      & > div {
        margin: 0;
      }
    }

    .ais-InstantSearch .ais-Menu ul{
      display: grid;
      grid-gap: 1rem;
      grid-auto-flow: row;
      grid-template-columns: repeat(6, 1fr);
      margin: 0;

      li {
        height: 100%;
        margin: 0;

        a {
          height: 100%;
        }
      }
    }
  }
}

@include media('<=tablet') {
  .photo--gallery {
    padding: 0 2rem;
  }

  .photo--gallery .ais-InstantSearch .ais-Menu ul {
    grid-template-columns: repeat(4, 1fr);
  }

  .photo--gallery .photo--gallery-thumbnails {
    grid-template-columns: repeat(5, 1fr);
  }
}

@include media('<phone') {
  .photo--gallery {
    .photo--gallery-thumbnails {
      grid-template-columns: repeat(3, 1fr);
      margin: 4rem 0;

      & > div {
        width: 100%;
        height: auto;
        margin: 0;
      }
    }
    .photo--gallery-categories {
      flex-direction: column;
      margin: 2rem 0 3rem;
      > div {
        margin: 0.5rem 0;
      }
    }
    .hooper-prev {
      left: 0;
      margin-top: -45px
    }

    .hooper-next {
      right: 0;
      margin-top: -45px
    }

    .ais-InstantSearch .ais-Menu ul {
      grid-template-columns: repeat(2,1fr);
    }
  }
}
