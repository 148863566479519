.page-three-col-slider {
    &__container {
        position: relative;
    }

    &__slider {
        padding-left: 0;

        img {
            width: 33.3vw;
            height: 45rem;
            object-fit: cover;
            object-position: center;
            font-family: 'object-fit: cover;object-position: center;';
        }
    }


    &__next,
    &__prev {
        position: absolute;
        top: 45%;
        z-index: 20;
        background-color: $color-red;
        padding: 1.5rem 2rem;
        cursor: pointer;

        &:hover {
            svg {
                animation-name: bounce-arrow;
                animation-timing-function: cubic-bezier(0.280, 0.840, 0.420, 1);
                animation-duration: 2s;
                animation-iteration-count: 1;
            }

        }

        @each $color, $hex in $colors {
            &.slider--color-#{$color} {
                background-color: $hex;
            }
        }
    }

    &__next {
        right: 0;

    }

    &__prev {
        left: 0;
    }
}

@include media ('<smallPhone') {
    .page-three-col-slider {
        &__slider {
            img {
                width: 100%;
                height: 320px;
            }
        }
    }
}
