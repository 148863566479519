body {
    overflow-x: hidden;
}

.tns-outer button {
  display: none;
}

.internal--page {
    background-color: white;
}

select.decorated option:hover {
    box-shadow: 0 0 10px 100px #1882A8 inset;
}

.awards-page .content-wrapper a{
    text-decoration: underline;
}

.spacer {
  display: block;
  width: 100%;
  height: 75px;
}

.text-12px {
    a, li, p {
        font-size: 12px !important;
    }
}
.text-14px {
    a, li, p {
        font-size: 14px !important;
    }
}
.text-16px {
    a, li, p {
        font-size: 16px !important;
    }
}
.text-18px {
    a, li, p {
        font-size: 18px !important;
    }
}
.text-20px {
    a, li, p {
        font-size: 20px !important;
    }
}
.text-24px {
    a, li, p {
        font-size: 24px !important;
    }
}

.main-wrapper {
    width: 90%;
    max-width: 1140px;
    margin: 70px auto;
}


.header-picture {
    width: 100%;
    height: 60px;
    text-align: center;
    position: relative;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    background-size: cover;
    background-position: center top;
    overflow: hidden;
    h1 {
        color: white;
        font-size: 9rem;
        line-height: 9rem;
        text-transform: uppercase;
        position: absolute;
        left: 50%;
        transform: translate(-50%);
        bottom: -12px;
    }

    img {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        object-fit: cover;
        object-position: center;
        font-family: 'object-fit: cover;object-position: center;';
        width: 100%;
        height: 100%;
    }

    @each $color, $hex in $colors {
        &.header-picture-color-#{$color} {
            &:before {
                content: '';
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                top: 0;
                background: linear-gradient(rgba(0,0,0,0) 50%, rgba($hex, .6) 100%);
            }
        }
    }

    @include media('>1200px') {
        height: 60px;
    }

    @include media('<=phone') {
        h1 {
            font-size: 10rem;
            line-height: 10rem;
        }
    }
}

.black-overlay {
    background: rgba(0,0,0,0.5);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.hidden {
    visibility: hidden;
    height: 0;
    margin: 0;
}

#block-398 h2 span {
    background-image: linear-gradient($color-dark-gray,$color-dark-gray);
}

#block-19922 h2 span,
#block-19839 h3 span {
    background-image: linear-gradient($color-section-blue,$color-section-blue);
}

a.anchor {
    display: block;
    position: relative;
    top: -120px;
    visibility: hidden;
}

.title-overlay {
    opacity: 0.05;
    position: absolute;
    text-align: center;
    width: 100%;
    z-index: 1;
    transform: rotate(0deg);
    top: 30px;
    left: 0;
    pointer-events: none;
    h1 {
        font-size: 15rem;
    }
    @include media('<tablet') {
        text-align: left;
        h1 {
            font-size: 10rem;
            margin: 2.5rem 10px 3rem;
        }
    }
}

@include media('>=largeScreen') {
    .header-picture h1 {
        font-size: 12rem;
        line-height: 12rem;
    }
}

@media(max-width: 768px){
    .header-picture {
        margin-bottom: 0;
        h1 {
            color: white;
            font-size: 5.3rem;
            line-height: 5.4rem;
            text-transform: uppercase;
            position: absolute;
            bottom: -8px;
            padding: 0 1rem;
            margin: 0;
            width: 100%;
        }
    }
}
