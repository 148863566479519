@import '../../css/variables';

.product--listing {
  .product--listing-container {
    max-width: $base-width;
    margin: 0 auto;

    h2, h3, h4, h5, h6 {
      margin-bottom: 3rem;
    }

    .product--listing--description {
      max-width: 50rem;
      font-size: 1.4rem;
      line-height: 2;
      font-family: $font-family-body;
      font-style: italic;
      font-weight: 300;
    }
  }
}

.product--category-page {
  .products-with-images-block {
    display: none;
  }
}

.product--category-header {
  display: flex;
  width: 90%;
  max-width: 1140px;
  margin: 70px auto;

  .product--category-header-left {
    width: 50%;
    overflow: hidden;
    position: relative;
    border-radius: 5px;
    box-shadow: 10px 10px 30px #b5b5b5;

    .category-title-container {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      z-index: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      pointer-events: none;
      .black-overlay {
        opacity: 1;
      }
    }

    .category-header-title {
      h1 {
        max-width: 370px;
        position: relative;
        font-size: 8.5rem;
        @include media('>1400px') {
          max-width: 450px;
        }
        @include media('<900px') {
          font-size: 6rem;
          max-width: 100%;
          text-align: center;
        }
      }
    }


    @each $color, $hex in $colors {
      &.slider--color-#{$color} .tns-nav button.tns-nav-active {
        background-color: $hex;
      }
    }

    .tns-outer {
      position: relative;
    }

    .tns-nav button {
      display: block;
      width: 2rem;
      height: 2rem;
      background: #262626;
      margin: 0 1rem;
      border-radius: 100%;
      outline: none;
    }

    .tns-nav {
      position: absolute;
      bottom: 4rem;
      left: 0;
      right: 0;
      z-index: 1;
      display: flex;
      justify-content: center;
    }

    .slider img {
      display: none;
    }

    .slider.tns-slider img {
      display: block;
    }
  }

  .product--category-header-right {
    width: 50%;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: start;

    .product--category-header-right-wrapper {
      width: 100%;
      max-width: 570px;

      .product--category-header-right-content {
        max-width: 80%;
        margin: 0 auto;
      }

      .product--category-header-description {
        margin: 3rem 0;
      }
    }

    .product--category-header-icon {
      margin-bottom: 3rem;
      width: 7.5rem;

      svg {
        width: 100%;
        height: auto;
      }

      @each $color, $hex in $colors {
        &.product--category-header-icon-color-#{$color} {
          color: $hex;
        }
      }
    }

    .button--type-download {
      padding: 0 25px 35px 0;
    }
  }
}

@include media('<tablet') {
  .product--category-header {
    display: block;
    margin: 4rem auto;


    .product--category-header-left {
      width: 100%;

      img {
        height: 32rem;
        width: 100%;
        object-fit: cover;
        object-position: center;
        font-family: 'object-fit: cover;';
      }
    }

    .product--category-header-right-content {
      max-width: 100% !important;
      width: 100%;
    }

    .product--category-header-right {
      width: 100%;
      padding: 4rem 0rem;
    }
  }
  .product-category-wrapper {
    position: relative;

    h1 {
      font-size: 4.7rem !important;
    }
  }

  .product--listing {
    .product--listing-container {
      padding: 100px 2% 0;

      .product--listing--description {
        max-width: 50rem;
        font-size: 1.4rem;
        line-height: 2;
        font-family: $font-family-body;
        font-style: italic;
        font-weight: 300;
      }
    }
  }

  .product--category-header .product--category-header-right .product--category-header-right-wrapper {
    max-width: 100%;
  }
}

#country-selector {
  margin: 5px 0 20px;
  width: 100%;
  justify-content: flex-end;

  p {
    font-size: 2rem;
    font-weight: bold;
    margin-right: 15px;
    color: #262626;
  }

  img {
    margin: 0 5px;
    width: 50px;
    height: auto;
    cursor: pointer;
    padding: 5px;
    border: 1px solid transparent;

    &.refined {
      border: 1px solid #707070;
    }
  }
  @include media('<tablet') {
    justify-content: flex-start;
  }
}

.flex {
  display: flex;
  @include media('<tablet') {
    flex-direction: column;
  }
}
@include media('<tablet') {
  #country-selector.flex, .hits-flags.flex{
    flex-direction: row;
  }
}


.flex-wrap {
  flex-wrap: wrap;
}

.justify-between {
  gap: 15px;
}

.items-center {
  align-items: center;
}
