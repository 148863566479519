.event-block {
    padding: 50px 0;
    margin: 0 auto;
    width: 80%;
    max-width: 1100px;
    display: block;
    background: white;

    p {
        font-weight: 400;
        font-family: $font-family-body;
    }
    .event-details {
        position: relative;
    }
    .activity-container {
        padding-bottom: 6rem;
        position: relative;
    }
    #event-link1 {
        position: absolute;
        bottom: -8rem;
    }
    #event-link2,
    #event-link3{
        position: absolute;
        bottom: -1.5rem;
    }

    #activity-link1,
    #activity-link2,
    #activity-link3{
        position: absolute;
        bottom: 0;
    }
    .header-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        > svg {
            width: 79px;

        }
        > h2 {
            padding-left: 25px;
        }
    }
    > .event-wrapper, > .activity-wrapper {
        display: flex;
        flex-direction: row;
        margin: 45px 0;
        justify-content: space-around;
    }
    > .event-wrapper {
        display: none;
        &.active {
            display: flex;
        }

        > .big-event {
            width: 45%;
            height: 500px;
            display: flex;
            flex-direction: column;
            margin: 5px;
            border: 1px solid #cccccc;

            .event-image img {
                height: 298px;
                width: 100%;
                object-fit: contain;
                object-position: center;
                font-family: 'object-fit: cover;object-position: center;';
            }
        }
        > .second-event {
            width: 55%;
            display: flex;
            flex-direction: column;
        }
        .container{
            .event-image img {
                height: 100%;
                width: 275px;
                object-fit: cover;
                font-family: 'object-fit: cover;';
            }

            .event-details{
                width: calc(100% - 275px)
            }
        }
        .event-details{
            padding: 25px 50px 0 40px;
            span {
                margin: 5px 0;
                font-weight: 900;
                text-transform: uppercase;
                display: block;
                font-family: $font-family-body;
            }
            h4{
                margin: 5px 0;
                font-weight: 500;
                display: block;
                font-family: $font-family-body;
            }
        }
    }
    > .activity-wrapper {
        display: none;
        &.active {
            display: flex;
        }

        .activity-details {
            & > div {
                max-height: 290px;
                overflow: hidden;
            }

            span{
                margin: 5px 0;
                font-weight: 900;
                text-transform: uppercase;
                display: block;
                font-family: $font-family-body;
            }
            h4{
                margin: 5px 0;
                font-weight: 500;
                display: block;
                font-family: $font-family-body;
            }
        }
    }
    .arrow {
        display: inline-block;
        margin: 0;
        padding: 0;
        color: black;
        background-image: url('../../../img/arrow-right.svg');
        background-position: center right;
        background-repeat: no-repeat;
        padding-right: 25px;
        margin: 10px 0 0 !important;
        color: $color-red;
        font-weight: bold;
        font-size: 14px;
        text-transform: uppercase;
    }
    .activity-wrapper {
        .activity-image img {
            width: 100%;
            height: 254px;
            object-fit: cover;
            font-family: 'object-fit: cover;';
        }
    }
    .second-event .container {
        margin: 5px;
        border: 1px solid #cccccc;
        height: 245px;
        display: flex;
        flex-direction: row;
    }
}

#activities h2{
    padding-left: 25px;
}

/* Activities */

#activities .activity-container {
    width: 32%;
    border: 1px solid #cccccc;
}
#activities .activity-details {
    padding: 40px 50px 10px 50px;
}


@include media('<phone') {
    .event-block {
        padding: 50px 5%;
        margin: 0;
        width: 100%;
        > .event-wrapper, > .activity-wrapper {
            display: flex;
            flex-direction: column;
            margin: 45px 0;
            justify-content: space-around;
        }
        h2 {
            font-size: 30pt;
        }
        > .event-wrapper {
            > .big-event {
                width: 100%;
                flex-direction: column;
                margin: 15px 0;
                height: 400px;
                .event-image img {
                    height: 160px;
                    width: 100%;
                }
            }
            > .second-event {
                width: 100%;
                display: flex;
                flex-direction: column;
            }
            .container{
                height: auto;
                margin: 15px 0;
                .event-image img {
                    height: 160px;
                    width: 100%;
                }
                .event-details{
                    width: 100%;
                    padding-bottom: 50px;
                }
            }
            .event-details{
                padding: 20px 4%;
                span {
                    margin: 5px 0;
                    font-weight: 900;
                    text-transform: uppercase;
                    display: block;
                    font-family: $font-family-body;
                }
                h4{
                    margin: 5px 0;
                    font-weight: 300;
                    display: block;
                    font-family: $font-family-body;
                }
            }
        }
        .second-event .container {
            flex-direction: column;
        }
        .event-pagination, .activity-pagination {
            span{
                padding: 10px 15px;
                font-size: 12px;
            }
        }
    }
    #activities .activity-container {
        width: 100%;
        margin: 15px 0;
        border: 1px solid #cccccc;
    }
    #activities .activity-details {
        padding: 40px 4%;
    }
    .spacer {
      height: 0px !important;
    }
    .spacer-mobile {
      display: block;
      width: 100%;
      height: 50px;
    }
    .activity-wrapper {
        .activity-image img {
            width: 100%;
            height: 160px;
        }
    }
}
