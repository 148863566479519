/**
 * variables.css
 *
 * Variables for the website
 *
 */
/* fonts */
$font-family-header: 'anton', sans-serif;
$font-family-body: 'museo-sans', sans-serif;
$font-family-menu: 'montserrat', sans-serif;

/* colours */
$color-dark-gray: #262626;
$color-red: #D2222A;
$color-gray: #F5F5F5;
$color-medium-gray: #A2A2A2;
$color-black : #000;


/* colours for sections */
$colors: (
    'default': #fff,
    'pink': #7B3917,
    'purple': #0D588E,
    'orange': #FA8807,
    'green': #00A894,
    'blue': #009CDC,
    'red': #D5001B,
    'dark-red': #D2222A,
    'gray': #8C8C8C,
    'dark-gray': #494949,
    'black': #000,
    'white': #fff,
    'undefined':#000
);

$color-section-pink: #7B3917;
$color-section-purple: #0D588E;
$color-section-orange: #FD6139;
$color-section-green: #00A894;
$color-section-blue: #00ADEF;
$color-section-red: #ED1818;
$color-section-gray: #9E9E9E;
$color-section-dark-gray: #494949;

/* grid */
$base-width: 114rem;

/* spacing */

/* Media queries */
$breakpoints: (smallPhone: 600px, phone: 768px, tablet: 1024px, desktop: 1200px, largeScreen: 1600px);
