/**
 * Drawings styling
 */
@import '../../../css/variables';

.drawings {
  .drawings--wrapper {
    margin: 3rem auto;
    max-width: $base-width;
  }

  .drawings--description {
    margin-top: 1rem;

    .button {
      width: 260px;
    }

    .drawing--buttons {
      display: flex;
      flex-flow: wrap;
      margin: 0 -1rem;
      width: 50%;

      & > a {
        display: block;
        margin: 1rem 1rem;
      }
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  .drawings--container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    margin: 3rem -1rem;

    .drawings--image {
      width: 50%;
      padding: 1rem 1rem;

      img {
        margin: 0 auto;
        max-height: 600px;
      }
    }
  }
}