/**
 * Category styling
 */
@import '../../../css/variables';

.product--listing {
  background: $color-gray;
  padding: 5rem 0;
  min-height: 528px;
  .product--listing-products {
    max-width: 114rem;
    margin: 0 auto;
    padding: 1rem 0;
  }

  .catalog-button {
    width: 280px;
    margin: 50px calc(50% - 140px) 0;
    a {
      line-height: 18px;
    }
  }

  .ais-SearchBox {
    margin: 2rem 0;

    form {
      display: flex;
    }

    .ais-SearchBox-input {
      background: url('../../../img/search-black.svg') no-repeat left center;
      padding: 1rem 0 1rem 5rem;
      font-size: 2rem;
      color: $color-medium-gray;
      border-bottom: 2px solid $color-medium-gray;
      outline: none;
      flex: 1;
    }

    button {
      display: none;
    }
  }

  .ais-HierarchicalMenu {
    .ais-HierarchicalMenu-list--lvl0 {
      list-style: none;
      margin: 0;
      padding: 0;
      display: flex;
      margin: 0 -2rem;
      align-items: flex-end;

      & > li {
        flex: 1;
        padding: 0 2rem 0;

        &.ais-HierarchicalMenu-item--not-selected {
          .product--category-photo-overlay {
            opacity: 0.7 !important;
          }
        }

        & > a {
          font-family: $font-family-body;
          font-size: 2rem;
          line-height: 2.4rem;
          color: $color-medium-gray;
          font-weight: 700;
          display: block;
          position: relative;
          padding: 2rem 0;
          transition: all 250ms ease-in;
          width: 100%;
          outline: none;

          span {
            font-size: 2rem;
            line-height: 2.4rem;
            font-weight: 700;
          }

          .ais-HierarchicalMenu-label {
            display: block;
            margin-bottom: 2rem;
            height: 50px;
          }

          .product--category-photo{
            width: 100%;
            height: 125px;
            background: white;
            border: 3px solid $color-medium-gray;
            position: relative;
            padding: 10px 0;
            .product--category-photo-overlay {
              position: absolute;
              top: 0;
              bottom: 0;
              left: 0;
              right: 0;
              opacity: 0;
              background: #fff;
            }
            img {
              height: 100%;
              margin: 0 auto;
              transition: all 250ms ease-in;
            }
          }

          svg {
            display: none;
          }
        }

        &.ais-HierarchicalMenu-item--selected {
          & > a {
            font-weight: 900;
          }
        }
      }

      &.tier2 {
        margin: 1rem 0 0;

        & > .ais-HierarchicalMenu-item {
          width: 100%;
        }
      }
    }

    .v-select {
      font-size: 2rem;
      font-family: $font-family-body;
      display: none;

      .vs__dropdown-toggle {
        border-radius: 0;
        border: 0;
        border-bottom: 3px solid #A2A2A2;
      }

      .vs__dropdown-menu {
        font-size: 1.6rem;
        font-weight: normal;
      }

      .vs__dropdown-option--highlight {
        background: red;
      }

      .vs__selected {
        color: #262626;
      }
    }

    .ais-HierarchicalMenu-list--lvl1 {
      list-style: none;
      padding: 0;
      display: flex;
      margin: 2rem -1rem 0;
      flex-wrap: wrap;
      & > li {
        margin: 1.1rem 1rem;
        & > a {
          font-family: $font-family-body;
          font-size: 1.8rem;
          line-height: 2.2rem;
          color: $color-medium-gray;
          font-weight: 700;
          display: inline-block;
          position: relative;
          background: none;
          border: 2px solid $color-medium-gray;
          padding: 1.7rem 3.5rem;
          outline: none;

          svg {
            position: absolute;
            bottom: -22px;
            left: 50%;
            margin-left: -28px;
            color: transparent;
            opacity: 0;
            transform: scaleY(0);
            transition: opacity 250ms ease-in, transform 250ms ease-in;
          }
        }

        &.ais-HierarchicalMenu-item--selected {
          & > a {
            font-weight: 900;

            svg {
              transform: scaleY(1);
              opacity: 1;
            }
          }
        }
      }
    }

    fieldset {
      legend {
        font-size: 2rem;
        font-weight: 700;
        font-family: "museo-sans", sans-serif;
        margin-bottom: 1rem;
        color: #262626;
      }

      & > div {
        margin-bottom: 0.5rem;

        input[type=radio] {
          position: absolute;
          margin: 0 !important;
          padding: 0 !important;
          opacity: 0;
          height: 0;
          width: 0;
          pointer-events: none;
        }

        input[type=radio] + label {
          user-select: none;
          cursor: pointer;
          line-height: 1.4;
          display: inline-flex;
          align-items: center;
          font-size: 1.5rem;
          font-weight: 600;
          font-family: "museo-sans", sans-serif;
          color: #262626;

          &:before {
            content: '';
            flex-shrink: 0;
            display: inline-block;
            width: 25px;
            height: 25px;
            background-color: transparent;
            border: 2px solid black;
            margin-right: 10px;
            transition: transform .2s, border .2s;
            background-repeat: no-repeat;
            background-position: center;
          }
        }

        input[type=radio]:checked + label {
          &:before {
            background-color: black;
            background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cg class='nc-icon-wrapper' fill-opacity='0.8' fill='%23000000'%3E%3Ccircle cx='8' cy='8' r='8' fill-opacity='0.8' fill='%23000000'%3E%3C/circle%3E%3C/g%3E%3C/svg%3E");
            background-size: 13px;
          }
        }

        input[type=radio]:focus + label {
          &:before {
            border-color: blue;
            box-shadow: 0 0 0 3px rgba(0,0,0,0.2);
          }
        }
      }
    }
  }

  @each $color, $hex in $colors {
    &.product--listing--color-#{$color} {
      .v-select {
        .vs__dropdown-option--highlight {
          background: $hex;
        }
        .vs__dropdown-toggle {
          border-color: $hex;
          .vs__actions svg path {
            fill: $hex;
          }
        }
      }

      .ais-HierarchicalMenu .ais-HierarchicalMenu-list--lvl0 > li.ais-HierarchicalMenu-item--selected {
        & > a {
          color: $hex;

          .product--category-photo {
            border-color: $hex;
          }
        }
      }

      .ais-HierarchicalMenu .ais-HierarchicalMenu-list--lvl0 > li {
        a:hover,
        a:active {
          color: $hex;

          .product--category-photo {
            border-color: $hex;
          }
        }
      }

      .ais-HierarchicalMenu .ais-HierarchicalMenu-list--lvl1 {

        & > li {
          &.ais-HierarchicalMenu-item--selected {
            & > a {
              color: lighten($hex, 45%);
              background: $hex;
              border-color: $hex;

              svg {
                color: $hex;
              }
            }
          }
        }
      }

      .product--box:active,
      .product--box:hover {
        box-shadow: 0 0 0 3px $hex;
      }


      fieldset > div {
        input[type=radio]:checked + label {
          &:before {
            background-color: lighten($hex, 25%);
          }
        }

        input[type=radio]:focus + label {
          &:before {
            border-color: darken($hex, 15%);
            box-shadow: 0 0 0 3px rgba($hex,0.2);
          }
        }
      }
    }
  }

  .ais-Hits-list {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    margin: 3rem -1.5rem;

    .ais-Hits-item {
      margin: 0 1.5rem 2rem 1.5rem;
      flex: 0 1 auto;
      width: 100%;
      max-width: calc((100% / 3) - 3rem);
    }

    .product--box {
      height: 100%;
      display: flex;
      flex-flow: column;
      border: 2px solid $color-medium-gray;
      background: #fff;
      transition: all 250ms ease-in;
      position: relative;
      padding-bottom: 6rem;

      .button {
        position: absolute;
        bottom: 0;
        left: 2rem;
      }

      .product--category--box-header {
        font-size: 2rem;
        line-height: 2.6rem;
        font-weight: 700;
        font-family: $font-family-body;
        color: $color-dark-gray;
        margin: 1rem 0;
      }

      .product--category--box-description {
        font-size: 1.4rem;
        line-height: 2;
        color: $color-dark-gray;
      }

      .product--category--box-image {
        max-width: 200px;
        width: 100%;
        height: 200px;
        margin: 25px auto !important;
        object-fit: contain;
      }

      .product--box-container {
        padding: 1rem 2rem 2rem;
      }
    }
  }
}

.hits-flags {
  position: absolute;
  top: 10px;
  right: 5px;
  img {
    width: 25px !important;
    height: auto;
    margin: 0 5px;
  }
}

.product--listing--color-gray {
  .ais-HierarchicalMenu .ais-HierarchicalMenu-list--lvl0 > li {
    a:hover,
    a:active {
      color: black !important;
      .product--category-photo {
        border-color: black !important;
      }
    }
  }
}

.product--listing {
  &--color-pink {
    .ais-SearchBox .ais-SearchBox-input {
      background: url('../../../img/search-pink.svg') no-repeat left center;
    }
  }

  &--color-purple {
    .ais-SearchBox .ais-SearchBox-input {
      background: url('../../../img/search-purple.svg') no-repeat left center;
    }
  }

  &--color-orange {
    .ais-SearchBox .ais-SearchBox-input {
      background: url('../../../img/search-orange.svg') no-repeat left center;
    }
  }

  &--color-green {
    .ais-SearchBox-input {
      background: url('../../../img/search-green.svg') no-repeat left center;
    }
  }

  &--color-blue {
    .ais-SearchBox .ais-SearchBox-input {
      background: url('../../../img/search-blue.svg') no-repeat left center;
    }
  }

  &--color-red {
    .ais-SearchBox .ais-SearchBox-input {
      background: url('../../../img/search-red.svg') no-repeat left center;
    }
  }

  &--color-dark-red {
    .ais-SearchBox .ais-SearchBox-input {
      background: url('../../../img/search-darkred.svg') no-repeat left center;
    }
  }

  &--color-gray {
    .ais-SearchBox .ais-SearchBox-input {
      background: url('../../../img/search-gray.svg') no-repeat left center;
    }
  }

  &--color-dark-gray {
    .ais-SearchBox .ais-SearchBox-input {
      background: url('../../../img/search-darkgray.svg') no-repeat left center;
    }
  }

  &--color-black {
    .ais-SearchBox .ais-SearchBox-input {
      background: url('../../../img/search-black.svg') no-repeat left center;
    }
  }

  &--color-white {
    .ais-SearchBox .ais-SearchBox-input {
      background: url('../../../img/search-white.svg') no-repeat left center;
    }
  }
}


@supports (display: grid) {
  .product--listing {
    .ais-Hits-list {
      display: grid;
      margin: 3rem 0;
      grid-gap: 2rem;
      grid-auto-flow: row;
      grid-template-columns: repeat(3, 1fr);

      .ais-Hits-item {
        width: 100%;
        height: 100%;
        margin: 0;
        max-width: 100%;
      }
    }
  }
}

@include media('<tablet') {
  .product--listing .ais-HierarchicalMenu .ais-HierarchicalMenu-list--lvl0 > li > a .ais-HierarchicalMenu-label {
    height: auto;
  }

  .product--listing .ais-HierarchicalMenu .ais-HierarchicalMenu-list--lvl0 > li > a .product--category-photo img {
    height: auto;
    max-height: 100%;
  }

  .product--listing .ais-HierarchicalMenu .ais-HierarchicalMenu-list--lvl0 > li > a .product--category-photo {
    height: 140px;
    display: flex;
    align-items: center;
    padding: 0px 5px;
  }

  .product--listing .product--listing-products {
    padding: 1rem 2rem;
  }

  .product--listing .ais-HierarchicalMenu .ais-HierarchicalMenu-list--lvl0 {
    margin: 0 -1rem;
  }

  .product--listing .ais-HierarchicalMenu .ais-HierarchicalMenu-list--lvl0 > li {
    padding: 0 1rem 0;
  }
}

@include media('<phone') {
    .ais-HierarchicalMenu-list {
        display: block;
    }
    .product--listing .ais-Hits-list {
        grid-template-columns: repeat(1, 1fr);
    }
    .product--listing .ais-HierarchicalMenu .ais-HierarchicalMenu-list--lvl0 {
      display: block;
    }
    .product--listing-products {
      padding: 0 2rem !important;
    }

  .product--listing .ais-HierarchicalMenu .ais-HierarchicalMenu-list--lvl0 > li > a .product--category-photo {
    height: auto;
  }

  .product--listing .ais-HierarchicalMenu ul.ais-HierarchicalMenu-list--lvl0 {
    display: grid;
    grid-auto-flow: row;
    grid-template-columns: repeat(3, 1fr);
    margin: 0;
    grid-gap: 1rem;
    row-gap: 2.5rem;

    & > li {
      flex: none;
      padding: 0;

      & > a {
        padding: 0;

        .ais-HierarchicalMenu-label {
          height: auto;
          margin-bottom: 1rem;
          font-size: 1.7rem;
          line-height: 1.2;
        }

        .product--category-photo {
          width: auto !important;
          height: 100px !important;
        }
      }
    }
  }

  .product--listing .ais-HierarchicalMenu-item .v-select {
    display: block;
  }

  .product--listing .ais-HierarchicalMenu-item fieldset {
    display: none;
  }

  .product--listing {
    min-height: 608px;
  }

  #block-22184,
  #block-22187,
  #block-22189 {
    .product--listing {
      min-height: 373px;
    }
  }
}
