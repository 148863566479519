.grey-section-with-icons {
    background-color: #E8E8E8;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 50px 0;

    .left-description {
        padding-top: 2rem;
    }

    > div {
        width: 50%;
    }

    > .left-content {
        max-width: 57rem;
        margin-left: auto;
        padding: 0 8rem 0 0;
    }

    .icon-wrapper{
        display: flex;
        flex-direction: row;
        margin: 50px 25px 50px 17rem;
        align-items: center;
    }

    .icon-content{
        margin: 0 0 0 50px;
        max-width: 350px;

        h5{
            font-family: $font-family-body;
            font-weight: 500;
            text-transform: uppercase;
            margin: 5px 0;
            max-width: 220px;
        }
    }

    .icon-container svg {
        width: 100px;
        height: auto;
    }

    .icon-container {
        svg {
            color: $color-red;
        }
    }

    h3{
        text-transform: uppercase;
    }

    .add-in-view-on-view {
        opacity:0;
        transition: 0.8s;
    }

    .add-delay1{
        transition-delay: 0.2s;

    }
    .add-delay2{
        transition-delay: 0.4s;

    }
    .add-delay3{
        transition-delay: 0.6s;
    }

    .add-delay4{
        transition-delay: 0.8s;
    }

    .in-view{
        opacity:1 !important;
    }
}

@include media('<=tablet') {
    .grey-section-with-icons  .left-content {
        padding: 0 5rem 0 2rem;
    }

    .grey-section-with-icons .icon-wrapper {
        margin: 5rem 2rem 5rem 5rem;
    }

    .grey-section-with-icons h2 {
        font-size: 4.5rem;
    }
}

@include media('<phone') {
    .grey-section-with-icons {
        flex-direction: column;
        padding: 4rem 2rem;
        > div {
            width: 100%;
        }
        .left-content {
            padding: 4rem 0 0;
        }
        .right-content {
            padding: 4rem 0 ;

            .icon-wrapper {
                margin: 0 0 2.5rem;
                &:nth-last-child(1){
                    margin-bottom: 0;
                }
            }
        }
        > .left-content  {
          padding-left: 1rem !important;
        }
    }
}
