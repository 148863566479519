/**
 * Downloads styling
 */
@import '../../../css/variables';

.videoLinks {
  .flex-video {
    display: flex;
    flex-wrap: wrap;
  }
  .downloads--right .button {
    margin: 5px;
  }
  .video-svg svg {
    width: 30px !important;
    left: -5px
  }
}

@include media('<=tablet') {
  .videoLinks {

  }
}

@include media ('<phone') {
  .videoLinks {

  }
}

@include media ('<smallPhone') {
  .videoLinks {

  }
}
