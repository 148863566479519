/**
 * Form styling
 */
@import '../../../css/variables';

.form--block {
  max-width: $base-width;
  margin: 0 auto;
}

form.fui-form {
  .fui-input, .fui-select {
    font-size: 1.5rem;
  }

  .fui-input {
    border: 0px;
    border-bottom: 2px solid #a2a2a2;
    padding: .8rem .75rem;

    &:focus,
    &:hover {
      border-bottom-color: #d5001b;
      box-shadow: none;
    }
  }

  .fui-label, .fui-legend {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0;
  }

  .fui-submit {
    background-color: #000;
    color: #fff;
    width: 100%;
    padding: 2rem 5rem;
    font-size: 1.4rem;
    line-height: 1;
    text-align: center;
    font-family: museo-sans,sans-serif;
    font-weight: 900;
    display: inline-block;
    text-decoration: none;
    cursor: pointer;
    transition: all .25s ease-in;
    text-transform: uppercase;
    border: 0;
  }
}

.fui-alert {
  font-size: 1.5rem;
  padding: 2rem;
  margin-top: 1rem;
}